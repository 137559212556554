import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

const Input: ComponentMultiStyleConfig = {
	parts: ["field", "element", "addon"],
	baseStyle: {},
	variants: {
		SearchIcon: {
			field: {
				border: "1px",
				borderColor: "#C8C6C4",
				borderRadius: "2px",
				py: "7px",
				pl: "32px",
				height: "40px",
				_placeholder: {
					fontSize: "lg",
					fontWeight: "light",
					color: "#323130",
				},
			},
			element: {
				color: "#3D48D1",
				fontSize: "lg",
				fontWeight: "black",
				pl: "8px",
				width: "24px",
				height: "40px",
			},
		},
		Simple: {
			field: {
				border: "1px",
				borderColor: "#C8C6C4",
				borderRadius: "2px",
				height: "38px",
				_placeholder: {
					fontSize: "lg",
					fontWeight: "normal",
					color: "#D2D0CE",
				},
			},
		},
		left_addon: {
			field: {
				border: "1px solid",
				borderColor: "grey.300",
				borderLeft: "0px",
				borderRadius: "2px",
			},
			addon: {
				color: "black.200",
				border: "1px solid",
				borderRight: "0px",
				borderColor: "grey.300",
				borderRadius: "2px",
				fontWeight: "light",
				fontSize: "lg",
				lineHeight: "20",
				background: "#F3F2F1",
			},
		},
		right_addon: {
			field: {
				border: "1px solid",
				borderColor: "grey.300",
				borderRight: "0px",
				borderRadius: "2px",
				h: "40px",
			},
			addon: {
				color: "black.900",
				border: "1px solid",
				borderLeft: "0px",
				borderColor: "grey.300",
				borderRadius: "2px",
				fontWeight: "light",
				fontSize: "lg",
				lineHeight: "20",
				background: "#F3F2F1",
				h: "40px",
			},
		},
		filled: {
			field: {
				bgColor: "grey.200",
				color: "grey.500",
				border: "1px",
				borderRadius: "0px",
				height: "38px",
				borderColor: "grey.300",
				fontSize: "lg",
				fontWeight: "light",
				_placeholder: "grey.100",
				opacity: "1 !important",
				_hover: {
					bg: "grey.200",
				},
			},
		},
	},
};

export default Input;
