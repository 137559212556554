import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
} from "@chakra-ui/react";

interface PropsTypes {
  title: any;
  my: any;
  content: any;
}

const AccordionHome: React.FC<PropsTypes> = ({ title, my, content }) => {
  return (
    <Accordion my={my} allowMultiple>
      <AccordionItem borderTopWidth="0rem">
        <Box>
          <AccordionButton pl="0rem">
            <Box flex="1" textAlign="left">
              <Heading
                as="h4"
                size={{ sm: "lg_medium", lg: "xl_medium" }}
                textTransform="initial"
              >
                {title}
              </Heading>
            </Box>
            <AccordionIcon w="2.5rem" h="2.5rem" />
          </AccordionButton>
        </Box>
        <AccordionPanel
          as="div"
          fontSize={{ sm: "md", lg: "lg" }}
          fontWeight={{ sm: "normal", lg: "medium" }}
          textTransform="initial"
          color="grey"
        >
          {content}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
export default AccordionHome;
