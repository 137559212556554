import { Box, Image, Flex, Heading, Link } from "@chakra-ui/react";

const Footer = () => {
    return (
    <Flex
    >
        <Heading
            as="p"
            size="md_light"
            textTransform="initial"
            color='#605E5C'
            mt="3.2rem"
        >
            Mito.money is a trademark owned by Funtech Global Communications Ltd. Devonshire House, Manor way, Borehamwood, Herts. WD6 1QQ, United Kingdom. A registered Payment institution in the UK with registration details FRN: 815146 MLR NO: 12803115
        </Heading>
    </Flex>
    );
};

export default Footer;
            