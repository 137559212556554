import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";

interface ToastProps {
  message: string;
  onClose: () => void;
  type: "success" | "error" | "alert" | "info";
}

const Toast: React.FC<ToastProps> = ({ message, onClose, type }) => {
  let borderColor, bg;

  switch (type) {
    case "success":
      borderColor = "green.500";
      bg = "#F2FEEF";

      break;
    case "error":
      borderColor = "red.700";
      bg = "#FDE7E9";

      break;
    case "alert":
      borderColor = "#F2CF3C";
      bg = "#FFF6D2";

      break;
    case "info":
      borderColor = "#72B7FD";
      bg = "#ECF5FF";

      break;
    default:
      borderColor = "green.500";
  }

  return (
    <Flex
      pos="relative"
      top="80px"
      justify="center"
      bg={bg}
      align="center"
      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
      h="85px"
      borderLeft="8px"
      borderColor={borderColor}
      borderRadius="8px"
    >
      <Flex alignItems="center" ml="15px" mr="15px" w="100%">
        {/* <Image
          src={icon}
          h="22px"
          w="22px"
          alt="icon"
        /> */}
        <Box m="0 auto">
          <Heading
            as="p"
            size="lg_medium"
            variant="primary"
            pl="10px"
            textTransform="initial"
          >
            {message}
          </Heading>
        </Box>
        <Button
          variant="transparent"
          _hover={{ color: "grey.400" }}
          onClick={onClose}
        >
          <AiOutlineClose fontSize="20px" color="#9B9B9B" />
        </Button>
      </Flex>
    </Flex>
  );
};

export default Toast;
