import { ComponentMultiStyleConfig } from "@chakra-ui/react";

const Switch: ComponentMultiStyleConfig = {
	parts: ["container", "track", "thumb"],

	variants: {
		basic: {
			track: {
				_checked: {
					bg: "purple.400",
				},
			},
			thumb: {},
		},
	},
};

export default Switch;
