import { Flex, Spinner } from "@chakra-ui/react";

const RelativeLoaderOutlet = () => (
  <Flex justify="center" align="center" w="100%" h="100%">
    <Flex justify="center" position="relative" zIndex={2}>
      <Spinner color="blue.400" w="20px" h="20px" />
    </Flex>
  </Flex>
);

export default RelativeLoaderOutlet;
