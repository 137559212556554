import superagent from "superagent";
import _, { isUndefined, isNull } from "lodash";

import config from "../clientConfig";
import Bugsnag from "@bugsnag/js";
import Cookies from "js-cookie";
import { isOnline, handleNetworkError } from "./networkUtils";

type Method = "get" | "post" | "put" | "patch" | "del";

const methods: Method[] = ["get", "post", "put", "patch", "del"];

function formatUrl(path: string) {
  if (/^(\/\/|http|https)/.test(path)) {
    return path;
  }
  const adjustedPath = path[0] === "/" ? path.substring(1) : path;

  const endpoint = `${config.apiUrl}${adjustedPath}`;

  return endpoint;
}

function clearLocalStorageExcept(excludedKeys: string[]) {
  for (let key in localStorage) {
    if (localStorage.hasOwnProperty(key) && !excludedKeys.includes(key)) {
    localStorage.removeItem(key);
    }
  }
}

const AuthIntercept = require("superagent-intercept")((err: any, res: any) => {
  if (res && res.status === 401) {
    clearLocalStorageExcept(['RH_COOKIE_DATA', 'affiliateId']);
    localStorage.removeItem("authToken");
    localStorage.removeItem("authUser");
    Cookies.remove("authToken");
    window.location.assign(`/login?redirect=${window?.location?.pathname}`);
  }
});

class ApiClient {
  public get: any;
  public post: any;
  public patch: any;
  public del: any;
  public put: any;

  constructor() {
    const self = this;

    methods.forEach((method: Method) => {
      this[method] = (path: string, { params, data, files }: any = {}) =>
        new Promise((resolve, reject) => {
          if (!isOnline()) {
            // Early return if offline
            reject("It seems you are offline. Please check your internet connection.");
            return;
          }
          const request = superagent[method](formatUrl(path));
          request.use(AuthIntercept);
          request.set({ apiKey: config.apiKey });

          // don't set empty params
          if (params) {
            const emptySting = (val: any) => val === "";
            const qp = _(params)
              .omitBy(isUndefined)
              .omitBy(isNull)
              .omitBy(emptySting)
              .value();

            request.query(qp);
          }

          if (data && !files) {
            request.send(data);
          }

          // Upload Files. its a multipart form
          if (files && files.length > 0) {
            files.forEach((file: any) => request.attach(file.name, file));

            self
              .filedNameValue(data)
              .forEach((keyVal) => request.field(keyVal[0], keyVal[1]));
          }

          // set auth token if found in local storage
          const authToken = localStorage.getItem("authToken");
          const fbp = Cookies.get("_fbp");
          const fbc = Cookies.get("_fbc");

          if (authToken) {
            request.set({ Authorization: "Bearer " + authToken });
          }
          const getAffiliateId = localStorage.getItem("affiliateId");
          request.set({"accept": "/", apiKey: config.apiKey, AffiliateId: getAffiliateId || '' });

          if (fbp) {
            request.set({ _fbp: fbp });
          }

          if (fbc) {
            request.set({ _fbc: fbc });
          }

          request.end((err: any, respnse: any = {}) => {
            const { body, error } = respnse;
            if (err) {
              const status = error?.status;

              if (status === 401) {
                clearLocalStorageExcept(['RH_COOKIE_DATA', 'affiliateId']);
                localStorage.removeItem("authToken");
                localStorage.removeItem("authUser");
                Cookies.remove("authToken");
                window.location.assign(
                  `/login?redirect=${window?.location?.pathname}`
                );
                return;
              } else {
                // Handle joi validation errors.
                if (body && body.validation) {
                  const messages = body.message.split(".");
                  const validationErrors: any = {};
                  body.validation.keys.forEach((key: any, idx: any) => {
                    validationErrors[key] = messages[idx]
                      .replace(`"${key}"`, "")
                      .trim();
                  });

                  reject({ message: validationErrors });
                  return;
                } else if (body && body.message) {
                  reject(body.message);
                  return;
                } else {
                  Bugsnag.notify("API error: ", err);
                  const errorMessage = handleNetworkError(err);
                  reject(errorMessage);
                }
              }
            } else {
              resolve(body);
            }
          });
        });
    });
  }

  filedNameValue(obj: any, pKey = "") {
    const self = this;
    if (!obj) {
      return [];
    }

    const res: any[] = [];

    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      const newKey = pKey ? `${pKey}[${key}]` : key;
      if (typeof val === "object") {
        self.filedNameValue(val, newKey).forEach((o) => {
          res.push([o[0], o[1]]);
        });
      } else if (val) {
        res.push([newKey, val]);
      }
    });

    return res;
  }
}

const api = new ApiClient();
export default api;
