import { Flex, SkeletonCircle } from "@chakra-ui/react";
import React from "react";

const SkeletonCircularLoader = () => {
  return (
    <Flex pt="32px">
      <Flex justifyContent="left" pl="20px">
        <SkeletonCircle size="12" pr="6px" w="40px" h="40px" />
      </Flex>
      <Flex justifyContent="left" pl="20px">
        <SkeletonCircle size="12" w="40px" h="40px" />
      </Flex>
      <Flex justifyContent="left" pl="20px">
        <SkeletonCircle size="12" w="40px" h="40px" />
      </Flex>
      <Flex justifyContent="left" pl="20px">
        <SkeletonCircle size="12" w="40px" h="40px" />
      </Flex>
    </Flex>
  );
};

export default SkeletonCircularLoader;
