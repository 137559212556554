import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

const Checkbox: ComponentMultiStyleConfig = {
	parts: ["control", "icon", "label"],

	variants: {
		SimpleCheck: {
			control: {
				border: "1px solid",
				borderColor: "purple.400",
				width: "20px",
				height: "20px",
				_checked: {
					borderColor: "purple.400",
					bg: "transparent",
					_hover: {
						bg: "transparent",
					},
				},
				_focus: {
					boxShadow: "none",
				},
			},
			icon: {
				color: "purple.400",
				fontSize: "15px",
				fontWeight: "light",
			},
			label: {
				fontSize: "md",
				fontWeight: "light",
				color: "black.900",
				lineHeight: "16px",
				m: "0px",
				pl: "8px",
			},
		},
	},
};

export default Checkbox;
