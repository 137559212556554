import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface State {
  cookieData: any;
}

const initialState: State = {
  cookieData: null,
};

export const CookieSlice = createSlice({
  name: "cookie",
  initialState,
  reducers: {
    setCookieData: (state, action: PayloadAction<any>) => {
      state.cookieData = action.payload;
    },
  },
});

export const { setCookieData } = CookieSlice.actions;

export default CookieSlice.reducer;
