import { Events } from "./event";

export const toastrMsg = (title: string, message: any) => {
  let toastrType: string = "";

  if (title === "Error") {
    Events.trigger("error", { message: message, toastrType });
  } else if (title === "Alert") {
    Events.trigger("alert", { message: message, toastrType });
  }else if (title === "Info") {
    Events.trigger("info", { message: message, toastrType });
  }else {
    Events.trigger("success", { message: message, toastrType });
  }
};