import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

const Select: ComponentMultiStyleConfig = {
	parts: ["field", "icon"],
	baseStyle: {},

	variants: {
		fill: {
			field: {
				bgColor: "grey.200",
				color: "grey.500",
				border: "1px",
				borderRadius: "0px",
				height: "38px",
				borderColor: "grey.300",
				fontSize: "lg",
				fontWeight: "light",
			},
			icon: {
				w: "auto",
				h: "auto",
				fontSize: "25px",
				color: "grey.300",
			},
		},

		fill_light: {
			field: {
				bgColor: "grey.200",
				color: "grey.500",
				border: "1px",
				borderRadius: "2px",
				height: "38px",
				borderColor: "grey.300",
				fontSize: "lg",
				fontWeight: "light",
			},
			icon: {
				w: "auto",
				h: "auto",
				fontSize: "25px",
				color: "grey.300",
			},
		},

		transparent: {
			field: {
				bgColor: "transparent",
				color: "black.100",
				border: "1px",
				borderRadius: "0px",
				height: "38px",
				borderColor: "grey.300",
				fontSize: "lg",
				fontWeight: "normal",
			},
			icon: {
				w: "auto",
				h: "auto",
				fontSize: "25px",
				color: "grey.300",
			},
		},
		transparent_curved: {
			field: {
				bgColor: "transparent",
				color: "black.900",
				border: "1px",
				borderRadius: "4px",
				height: "24px",
				w: { sm: "62px", xl: "70px" },
				borderColor: "grey.300",
				fontSize: "md",
				fontWeight: "light",
				lineHeight: "16px",
			},
			icon: {
				w: "auto",
				h: "auto",
				fontSize: "25px",
				color: "grey.300",
			},
		},
		WithIcon: {
			field: {
				minHeight: "68px",
				color: "black.200",
				border: "1px",
				borderRadius: "0px",
				height: "38px",
				borderColor: "grey.300",
				fontSize: "lg",
				fontWeight: "normal",
				display: "flex",
			},
			iicon: {
				w: "auto",
				h: "auto",
				fontSize: "25px",
				color: "grey.300",
			},
		},
	},
	// defaultProps: {
	//     size: 'lg',
	//     variant: 'solid',
	//   },
};

export default Select;
