import { Box, Heading } from "@chakra-ui/react";

interface WelcomeMessageProps {
  userName: string;
}

const WelcomeMessage: React.FC<WelcomeMessageProps> = ({ userName }) => {
  return (
    <Box>
      <Heading
        size={{ sm: "xxxl_light", lg: "xxxl_light", xl: "xxxxl_light" }}
        variant="black"
        cursor="default"
      >
        Welcome&nbsp;
        {userName}
      </Heading>
    </Box>
  );
};

export default WelcomeMessage;
