import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { List, ListItem, ListIcon, Box, Tooltip, Link, useMediaQuery, Heading, Flex } from "@chakra-ui/react";
import {
  RiBarChartFill,
  RiLayoutMasonryFill,
} from "react-icons/ri";
import { BsPersonFill } from "react-icons/bs";
import RouteLink from "../../components/form-element/RouteLink";
import { logout } from "../../redux/modules/auth";
import { flushAccount } from "../../redux/modules/account";
import { flushKYC } from "../../redux/modules/kyc";
import {
  resetAmountData,
  resetTransaction,
} from "../../redux/modules/transactions";
import AppLink from "../../components/AppLink";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { FaTimes } from 'react-icons/fa';
import { Icon } from "@chakra-ui/react";
import {ReactComponent as Support} from '../../assets/images/Vector2.svg';
import {ReactComponent as Compliance} from '../../assets/images/Vector3.svg';
import {ReactComponent as Settings} from '../../assets/images/Vector4.svg';
import {ReactComponent as LogOut} from '../../assets/images/Vector5.svg';

const SidebarMenuListitems = (props: any) => {
  const { isSidebarCollapased } = props;
  const {kycStarted, user, tenancyConfigData} = useAppSelector((state: any) => ({
    kycStarted: state.kyc.kycStarted,
    user: state.account.userData,
    tenancyConfigData: state.affiliate.multiTenancyConfigData
  }));
  let navigate = useNavigate();
  let location = useLocation();
  let dispatch = useAppDispatch();
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  let [menus] = useState([
    {
      id: 1,
      icon: RiLayoutMasonryFill,
      title: "Overview",
      path: "/overview",
    },
    {
      id: 2,
      icon: RiBarChartFill,
      title: "Transactions",
      path: "/transactions",
    },
    {
      id: 3,
      icon: BsPersonFill,
      title: "Recipients",
      path: "/beneficiaries",
    },
    {
      id: 4,
      icon: Compliance,
      title: "Compliance",
      path: "/compliance",
    },
    {
      id: 5,
      icon: Support,
      title: "Support",
      path: "/support",
    },
    {
      id: 6,
      icon: Settings,
      title: "Settings",
      path: "/settings",
    },
  ]);

  const logoutUser = () => {
    dispatch(logout());
    dispatch(flushAccount());
    dispatch(flushKYC());
    dispatch(resetTransaction());
    dispatch(resetAmountData());
    navigate("/");
  };

  return (
    <Box pos="relative">
      {isMobile && !tenancyConfigData.isFrame &&
        <Flex pt="7" justifyContent={'flex-end'}>
          <Icon as={FaTimes} fontSize={'24px'} bg="#9B9B9B" p="2" color={'white'} borderRadius={'100%'} />
        </Flex>
      }
      <List variant="SideBar" mt={tenancyConfigData.isFrame && '3.2rem'} w="100%" className="sidebarlist">
        {menus.map((menu) => (
          <Tooltip
            isDisabled={isSidebarCollapased}
            placement="bottom"
            label={menu.title}
            key={menu.id}
          >
            <ListItem
              whiteSpace="nowrap"
              role="group"
              key={menu.id}
              style={{pointerEvents: kycStarted ? "none": 'auto'}}
              color={`${
                location.pathname.includes(menu.path) ? "var(--dynamicPrimaryColor)" : "var(--dynamicNavBarColor)"
              }`}
              fill={`${
                location.pathname.includes(menu.path) ? "var(--dynamicPrimaryColor)" : "var(--dynamicNavBarColor)"
              }`}
              fontWeight={`${
                location.pathname.includes(menu.path) ? "500" : "300"
              }`}
            >
              <AppLink href={menu.path} >
                <ListIcon
                  as={menu.icon}
                  className={`${
                    location.pathname.includes(menu.path) ? "active" : ""
                  }`}
                  fill={`${
                    location.pathname.includes(menu.path) ? "var(--dynamicPrimaryColor)" : "var(--dynamicNavBarColor)"
                  }`}
                  width={'20px'}
                  height={'20px'}
                />
              </AppLink>
              <RouteLink
                className="sideNav_headings"
                to={menu.path}
                text={menu.title}
              />
            </ListItem>
          </Tooltip>
        ))}
        {isMobile && !tenancyConfigData.isFrame &&
          <Box
            py="10"
            borderTop="1px"
            borderBottom="1px"
            mb="10"
            borderColor={'#E1DFDD'}
          >
            <ListItem
                role="group"
                onClick={() => navigate("#")}
                style={{pointerEvents: kycStarted ? "none": 'auto'}}
                color="var(--dynamicNavBarColor)"
              >
                <Link className="sideNav_headings">How it Works</Link>
            </ListItem>
            <ListItem
                role="group"
                onClick={() => navigate("/faq")}
                style={{pointerEvents: kycStarted ? "none": 'auto'}}
                color="var(--dynamicNavBarColor)"
              >
                <Link className="sideNav_headings">FAQ</Link>
            </ListItem>
            <ListItem
                role="group"
                onClick={() => navigate("#")}
                style={{pointerEvents: kycStarted ? "none": 'auto'}}
                color="var(--dynamicNavBarColor)"
                mb="0"
              >
                <Link className="sideNav_headings">Help</Link>
            </ListItem>
          </Box>
        }
        <Tooltip
          isDisabled={isSidebarCollapased}
          placement="bottom"
          label="Logout"
        >
          <ListItem
            role="group"
            onClick={logoutUser}
            whiteSpace="nowrap"
            key="6"
            style={{pointerEvents: kycStarted ? "none": 'auto'}}
            color="var(--dynamicNavBarColor)"
          >
            <ListIcon as={LogOut} fill="var(--dynamicNavBarColor)"/>
            <Link className="sideNav_headings">Logout</Link>
          </ListItem>
        </Tooltip>
      </List>
    </Box>
  );
};

export default SidebarMenuListitems;
