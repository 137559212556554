import { Link, LinkProps } from 'react-router-dom';
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react";
import { ReactNode } from 'react';

// type AppLinkProps = ChakraLinkProps & LinkProps;

interface AppLinkProps {
  href: string;
  children: ReactNode;
  [key: string]: any; // This will accept any other props like `ml`, `p`, etc.
}

export default function AppLink({ href, children, ...props }: AppLinkProps) {
  return (
    <Link to={href}>
      <ChakraLink as="div" {...props} display="inline-block">
        {children}
      </ChakraLink>
    </Link>
  );
}
