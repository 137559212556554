
import { Route, Routes } from "react-router-dom";
import { privateRoutes, publicRoutes } from "../../router/routes";
import PrivateRoute from '../layout/PrivateRoute';
import "../../assets/style/main.scss";
import {
  useToastAlertHook,
  useToastErrorHook,
  useToastInfoHook,
  useToastSuccessHook,
} from "../../components/toaster/Toaster";
import { Events } from "../../utils/event";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { useAppSelector } from "../../redux/hooks";
import Loading from "../loading";

const App = () => {
  const { AffiliateLoading } = useAppSelector(
    (state) => ({
      AffiliateLoading: state.affiliate.AffiliateLoading,
    })
  );

  const [, SuccessToast] = useToastSuccessHook();
  const [, ErrorToast] = useToastErrorHook();
  const [, AlertToast] = useToastAlertHook();
  const [, InfoToast] = useToastInfoHook();

  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_TOKEN || "",
    plugins: [new BugsnagPluginReact()],
    appVersion: "0.1.0",
    releaseStage: "Development"
  });

  Events.on("success", "scu", (data: any) => {
    SuccessToast({ message: data?.message, status: "success" });
  });

  Events.on("error", "rre", (data: any) => {
    ErrorToast({ message: data.message, status: "error" });
  });
  Events.on("alert", "ral", (data: any) => {
    AlertToast({ message: data.message, status: "alert" });
  });

  Events.on("info", "rin", (data: any) => {
    InfoToast({ message: data.message, status: "info" });
  });
  
  if(AffiliateLoading) {
    return <Loading />;
  }

  return (
      <Routes>
        {publicRoutes}
        <Route element={<PrivateRoute />}>
          {privateRoutes}
        </Route>
      </Routes>
  );
};

export default App;
