import { Skeleton, Stack, Box, useMediaQuery } from "@chakra-ui/react";
import React from "react";

const ServicesSkeleton = () => {
  const [isMediumScreen] = useMediaQuery(
    "(min-width: 991px) and (max-width: 1366px)"
  );

  return (
    <Box pt="24px" padding="2" bg="white">
      <Stack>
        <Skeleton
          height={isMediumScreen ? "32px" : "40px"}
          borderRadius={{ sm: "15px", lg: "20px" }}
          m={0}
          mb="12px"
        />
        <Skeleton
          height={isMediumScreen ? "32px" : "40px"}
          borderRadius={{ sm: "15px", lg: "20px" }}
          m={0}
          mb="12px !important"
        />
        <Skeleton
          height={isMediumScreen ? "32px" : "40px"}
          borderRadius={{ sm: "15px", lg: "20px" }}
          m={0}
        />
      </Stack>
    </Box>
  );
};

export default ServicesSkeleton;
