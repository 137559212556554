import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sortBy } from "lodash";

import api from "../../utils/apiClient";

export interface KYCState {
	loading: any;
	valid: boolean;
	isNewUser: boolean;
	amlCheckResult: string;
	amlCheck: boolean;
	idCheck: boolean;
	statusKYC: any;
	userIDKYC: any;
	countries: any;
	biodateValues: any;
	selfiData: any;
	journeyId: any;
	getJourneyId: any;
	selfi: any;
	amlData: any;
	idData: any;
	idType: any;
	bidValues: any;
	referValues: any;
	referId: boolean;
	renewKyc: any;
	kycJourney: any;
	isBusinessProfile: any;
	isBusinessProfileUpdate: any;
	isTransactionAllowed: any;
	kycCheckStatus: any;
	kycUserStatus: any;
	kycQrcodeData: any;
	kycErrorMessage: any;
	kycStarted: any;
	KYCStatusServerUpdate: any;
	kycJourneyStatus: any;
}

const initialState: KYCState = {
	loading: {
		showLoader: false,
		checkKYCStatusLoading: false,
		checkIDLoading: false,
		isShowLoader: false,
		checkAdditionalIDLoading: false,
		countryLoader: false,
		renewKycLoader: false,
		kycJourneyLoader: false,
		kycJourneyStatusLoader: false,
	},
	valid: false,
	isNewUser: true,
	amlCheckResult: "",
	amlCheck: false,
	kycStarted: false,
	idCheck: true,
	isBusinessProfile: false,
	isBusinessProfileUpdate: false,
	isTransactionAllowed: false,
	statusKYC: {},
	userIDKYC: {},
	countries: [],
	biodateValues: {},
	selfiData: [],
	journeyId: "",
	getJourneyId: "",
	kycUserStatus: "",
	kycErrorMessage: "",
	selfi: "",
	amlData: null,
	idData: [],
	idType: "",
	bidValues: null,
	referValues: {},
	referId: false,
	kycCheckStatus: false,
	kycQrcodeData: "",
	renewKyc: {},
	kycJourney: {},
	KYCStatusServerUpdate: {},
	kycJourneyStatus: {},
};

export const checkKYCStatus = createAsyncThunk(
	"/KYC/Status",
	async (input: any) => {
		const response = await api.get(
			`/KYC/Status?api-version=${input.apiVersion}`
		);
		return response;
	}
);

export const KycGetjourney = createAsyncThunk(
	"/KYC/Getjourney",
	async (id: any) => {
		const response = await api.get(`/KYC/Getjourney?id=${ id }`);
		return response;
	}
);

export const checkID = createAsyncThunk("/KYC/IdCheck", async (data: any) => {
	const response = await api.post("/KYC/IdCheck", { data });
	return response.data;
});

export const checkAdditionalID = createAsyncThunk(
	"/KYC/AdditionalDocumentUpload",
	async (data: any) => {
		const response = await api.post("/KYC/AdditionalDocumentUpload", { data });
		return response.data;
	}
);

export const selfieCheck = createAsyncThunk(
	"/KYC/SelfieCheck",
	async (data: any) => {
		const response = await api.post("/KYC/SelfieCheck", { data });
		return response.data;
	}
);

export const amlCheck = createAsyncThunk("/KYC/AmlCheck", async (data: any) => {
	const response = await api.post("/KYC/AmlProdCheck", { data });
	return response.data;
});

export const getCountry = createAsyncThunk("Static/Countries", async () => {
	const response = await api.get("Static/Countries");
	let res = sortBy(response.data, [(o) => o.name]);
	return res;
});

export const renewKycData = createAsyncThunk(
	"/KYC/RenewKyc",
	async (data: any) => {
		const response = await api.post("/KYC/RenewKyc", { data });
		return response.data;
	}
);

export const KycQrcode = createAsyncThunk(
	"/KYC/QRCode",
	async (data: any) => {
		const response = await api.post("/KYC/QRCode", { data });
		return response.data;
	}
);

export const KYCStatusServerUpdate = createAsyncThunk(
	"/KYC/KYCStatusServerUpdate",
	async (data: any) => {
		const response = await api.post("/KYC/KYCStatusServerUpdate", { data });
		return response.data;
	}
);

export const KycJourneyStatus = createAsyncThunk(
	"/KYC/KYCJourneyStatus",
	async (id: any) => {
		const response = await api.get(`/KYC/KYCJourneyStatus?sessionId=${ id }`);
		return response;
	}
);

export const kycSlice = createSlice({
	name: "KYC",
	initialState,
	reducers: {
		handlingSelfiData: (state, action) => {
			state.selfi = action.payload;
		},
		handlingIDData: (state, action) => {
			state.idData = action.payload;
		},
		handlingIDType: (state, action) => {
			state.idType = action.payload;
		},
		handlingBiodateValues: (state, action) => {
			state.biodateValues = action.payload;
		},
		handlingBIDValues: (state, action) => {
			state.bidValues = action.payload;
		},
		handlingRenewKyc: (state, action) => {
			state.renewKyc = action.payload;
		},
		handlingKycUserStatus: (state, action) => {
			state.kycUserStatus = action.payload;
		},
		handlingKycErrorMessage: (state, action) => {
			state.kycErrorMessage = action.payload;
		},
		handlingKycStarted: (state, action) => {
			state.kycStarted = action.payload;
		},
		flushKYC: (state) => {
			state.bidValues = null;
			state.biodateValues = null;
			state.idData = null;
			state.userIDKYC = null;
			state.referId = false;
			state.referValues = null;
			state.idType = "";
			state.selfi = "";
			state.statusKYC = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(checkKYCStatus.pending, (state) => {
				state.loading.checkKYCStatusLoading = true;
			})
			.addCase(checkKYCStatus.rejected, (state) => {
				state.loading.checkKYCStatusLoading = false;
			})
			.addCase(checkKYCStatus.fulfilled, (state, action) => {
				state.loading.checkKYCStatusLoading = false;
				state.statusKYC = action.payload.data;
				state.valid = action.payload.data.valid;
				state.amlCheckResult = action.payload.data.amlCheckResult;
				state.isNewUser = action.payload.data.isNewUser;
				state.amlCheck = action.payload.data.amlCheck;
				state.idCheck = action.payload.data.idCheck;
				state.isBusinessProfile = action.payload.data.isBusinessProfile;
				state.isBusinessProfileUpdate = action.payload.data.isBusinessProfileUpdate;
				state.isTransactionAllowed = action.payload.data.isTransactionAllowed;
				state.getJourneyId = action.payload.data.journeyId;
			})
			.addCase(checkID.pending, (state) => {
				state.loading.checkIDLoading = true;
			})
			.addCase(checkID.rejected, (state) => {
				state.loading.checkIDLoading = false;
			})
			.addCase(checkID.fulfilled, (state, action) => {
				state.loading.checkIDLoading = false;
				state.userIDKYC = action.payload;
				state.journeyId = state.userIDKYC.journeyId;
			})
			.addCase(checkAdditionalID.pending, (state) => {
				state.loading.checkAdditionalIDLoading = true;
			})
			.addCase(checkAdditionalID.rejected, (state) => {
				state.loading.checkAdditionalIDLoading = false;
			})
			.addCase(checkAdditionalID.fulfilled, (state, action) => {
				state.loading.checkAdditionalIDLoading = false;
				state.referValues = action.payload;
				state.referId = true;
			})

			.addCase(getCountry.pending, (state) => {
				state.loading.countryLoader = true;
			})
			.addCase(getCountry.rejected, (state) => {
				state.loading.countryLoader = false;
			})
			.addCase(getCountry.fulfilled, (state, action) => {
				state.loading.countryLoader = false;
				state.countries = action.payload;
			})

			.addCase(selfieCheck.pending, (state) => {
				state.loading.showLoader = true;
			})
			.addCase(selfieCheck.rejected, (state) => {
				state.loading.showLoader = false;
			})
			.addCase(selfieCheck.fulfilled, (state, action) => {
				state.loading.showLoader = false;
				state.selfiData = action.payload.data;
			})

			.addCase(amlCheck.pending, (state) => {
				state.loading.isShowLoader = true;
			})
			.addCase(amlCheck.rejected, (state) => {
				state.loading.isShowLoader = false;
			})
			.addCase(amlCheck.fulfilled, (state, action) => {
				state.loading.isShowLoader = false;
				state.amlData = action.payload.data;
			})
			.addCase(renewKycData.pending, (state) => {
				state.loading.renewKycLoader = true;
			})
			.addCase(renewKycData.rejected, (state) => {
				state.loading.renewKycLoader = false;
			})
			.addCase(renewKycData.fulfilled, (state, action) => {
				state.loading.renewKycLoader = false;
				state.renewKyc = action.payload;
			})
			.addCase(KycGetjourney.pending, (state) => {
				state.loading.kycJourneyLoader = true;
			})
			.addCase(KycGetjourney.rejected, (state) => {
				state.loading.kycJourneyLoader = false;
			})
			.addCase(KycGetjourney.fulfilled, (state, action) => {
				state.loading.kycJourneyLoader = false;
				state.kycJourney = action.payload;
				state.userIDKYC = action.payload;
				state.journeyId = state.userIDKYC.journeyId;
			})
			.addCase(KycQrcode.pending, (state) => {
				state.loading.KycQrcodeLoader = true;
			})
			.addCase(KycQrcode.rejected, (state) => {
				state.loading.KycQrcodeLoader = false;
			})
			.addCase(KycQrcode.fulfilled, (state, action) => {
				state.loading.KycQrcodeLoader = false;
				state.kycQrcodeData = action.payload;
			})
			.addCase(KycJourneyStatus.pending, (state) => {
				state.loading.kycJourneyStatusLoader = true;
			})
			.addCase(KycJourneyStatus.rejected, (state) => {
				state.loading.kycJourneyStatusLoader = false;
			})
			.addCase(KycJourneyStatus.fulfilled, (state, action) => {
				state.loading.kycJourneyStatusLoader = false;
				state.kycJourneyStatus = action.payload;
			})
			.addCase(KYCStatusServerUpdate.pending, (state) => {
				state.loading.kycJourneyStatusLoader = true;
			})
			.addCase(KYCStatusServerUpdate.rejected, (state) => {
				state.loading.kycJourneyStatusLoader = false;
			})
			.addCase(KYCStatusServerUpdate.fulfilled, (state, action) => {
				state.KYCStatusServerUpdate = action.payload;
			});
	},
});

export const {
	handlingBiodateValues,
	handlingRenewKyc,
	handlingSelfiData,
	handlingIDData,
	handlingIDType,
	handlingBIDValues,
	flushKYC,
	handlingKycUserStatus,
	handlingKycErrorMessage,
	handlingKycStarted,
} = kycSlice.actions;
export default kycSlice.reducer;
