import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../utils/apiClient";
import { TransactionState } from "../../models/interfaces/transactions";
import getUrlQueryString from "../../utils/getUrlQueryString";

const initialState: TransactionState = {
  transactions: [],
  selectTransaction: false,
  addTransaction: { userTransaction: {} },
  loading: {
    transactionDetailLoader: false,
    showTransactionLoader: false,
    sendMoneyloader: false,
    sendAirtimeLoader: false,
    sendBillLoader: false,
    showScheduledTxnLoader: false,
    cancelTransactionLoader: false,
    transactionFileDetailLoader: false,
  },
  amountData: {},
  paymentDet: {},
  receiverData: {},
  setCollectionMode: null,
  transactionDetails: {},
  resendTransaction: false,
  setDirection: {},
  fromBen: false,
  topUpPack: {},
  moneyTransferCorridor: "",
  mobileTopUpCorridor: "",
  billPaymentCorridor: "",
  recurringTxnDetails: {},
  laterTxnDetails: {},
  scheduledTransactions: [],
  isCancelTxn: false,
  isTxnScheduled: false,
  getTransactionFiles: {},
  TransactionFilesUpload: {},
  paymentMode: "",
};

export const getTransactionsList = createAsyncThunk(
  "Transaction/ListTransaction",
  async (data: any) => {
    const response = await api.get(
      `Transaction/ListTransaction${getUrlQueryString(data)}`
    );
    return response;
  }
);

export const getListScheduledTransactions = createAsyncThunk(
  "Transaction/ListTransaction",
  async (data: any) => {
    const response = await api.get(
      `Transaction/ScheduledTransactionGroupList${getUrlQueryString(data)}`
    );
    return response;
  }
);

// export const getScheduledTransactionList = createAsyncThunk(
//   "Transaction/ScheduledTransaction",
//   async () => {
//     const response = await api.get("Transaction/ScheduledTransaction");
//     return response;
//   }
// );

export const getScheduledTransactions = createAsyncThunk(
  "Transaction/ScheduledTransactionItemList",
  async (id: any) => {
    const response = await api.get(
      `Transaction/ScheduledTransactionItemList?ScheduledTransactionGroupId=${id}`
    );
    return response;
  }
);

export const cancelScheduledTransaction = createAsyncThunk(
  "Transaction/CancelScheduledTransaction",
  async (id: any) => {
    const response = await api.get(
      `Transaction/CancelScheduledTransaction?ScheduledTransactionId=${id}`
    );
    return response.data;
  }
);

export const cancelGroupScheduledTransaction = createAsyncThunk(
  "Transaction/CancelScheduledTransaction",
  async (groupId: any) => {
    const response = await api.get(
      `Transaction/CancelScheduledTransactionGroup?ScheduledTransactionGroupId=${groupId}`
    );
    return response.data;
  }
);

export const transactionDetails = createAsyncThunk(
  "Transaction/GetTransaction",
  async (id: any) => {
    const response = await api.get(`Transaction/GetTransaction?Id=${id}`);
    return response.data;
  }
);

export const sendMoney = createAsyncThunk(
  "Transaction/SendMoney",
  async (data: any) => {
    const response = await api.post("/Transaction/SendMoney", { data });
    return response.data;
  }
);

export const sendAirtime = createAsyncThunk(
  "Transaction/Airtime",
  async (data: any) => {
    const response = await api.post("/Transaction/Airtime", { data });
    return response.data;
  }
);

export const sendBillPayment = createAsyncThunk(
  "Transaction/BillPayment",
  async (data: any) => {
    const response = await api.post("/Transaction/BillPayment", { data });
    return response.data;
  }
);

export const getTransactionFiles = createAsyncThunk(
  "Transaction/GetTransactionFiles",
  async (id: any) => {
    const response = await api.get(
      `Transaction/GetTransactionFiles?TransactionId=${id}`
    );
    return response;
  }
);

export const TransactionFilesUpload = createAsyncThunk(
  "Transaction/TransactionFilesUpload",
  async (data: any) => {
    const response = await api.post("/Transaction/TransactionFilesUpload", { data });    
    return response;
  }
);


export const transactionSlice = createSlice({
  name: "Transactions",
  initialState,
  reducers: {
    handlingAmountData: (state, action) => {
      state.amountData = action.payload;
    },
    handlingReceiverData: (state, action) => {
      state.receiverData = action.payload;
    },
    handlingCollectionMode: (state, action) => {
      state.setCollectionMode = action.payload;
    },
    handlingDirection: (state, action) => {
      state.setDirection = action.payload;
    },
    selectedTopUpPack: (state, action) => {
      state.topUpPack = action.payload;
    },
    resetAmountData: (state) => {
      state.amountData = {};
      state.receiverData = {};
      state.setCollectionMode = null;
      state.paymentDet = {};
      state.resendTransaction = false;
      state.setDirection = "";
      state.fromBen = false;
      state.topUpPack = {};
      state.recurringTxnDetails = {};
      state.laterTxnDetails = {};
      state.isTxnScheduled = false;
    },
    resetCollectionMode: (state) => {
      state.setCollectionMode = null;
    },
    resetLaterTxnDetails: (state) => {
      state.laterTxnDetails = {};
    },
    resetRecurringTxnDetails: (state) => {
      state.recurringTxnDetails = {};
    },
    resetTransaction: (state) => {
      state.transactions = [];
      state.moneyTransferCorridor = "";
      state.mobileTopUpCorridor = "";
      state.billPaymentCorridor = "";
    },
    handleResendTransaction: (state) => {
      state.resendTransaction = true;
    },
    isScheduleTransaction: (state, action) => {
      state.isTxnScheduled = action.payload;
    },
    handleTransactionFromRecipient: (state) => {
      state.fromBen = true;
    },
    setMoneyTransferCorridor: (state, action) => {
      state.moneyTransferCorridor = action.payload;
    },
    setMobileTopUpCorridor: (state, action) => {
      state.mobileTopUpCorridor = action.payload;
    },
    setBillPaymentCorridor: (state, action) => {
      state.billPaymentCorridor = action.payload;
    },
    handleRecurringTransaction: (state, action) => {
      state.recurringTxnDetails = action.payload;
    },
    handleLaterTransaction: (state, action) => {
      state.laterTxnDetails = action.payload;
    },
    clearPaymentDet: (state) => {
      state.paymentDet = {};
    },
    setPaymentMode: (state, action) => {
      state.paymentMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionsList.pending, (state) => {
        state.loading.showTransactionLoader = true;
      })
      .addCase(getTransactionsList.rejected, (state) => {
        state.loading.showTransactionLoader = false;
      })
      .addCase(getTransactionsList.fulfilled, (state, action) => {
        state.loading.showTransactionLoader = false;
        state.transactions = action.payload;
      })
      // .addCase(getListScheduledTransactions.pending, (state) => {
      //   state.loading.showScheduledTxnLoader = true;
      // })
      // .addCase(getListScheduledTransactions.rejected, (state) => {
      //   state.loading.showScheduledTxnLoader = false;
      // })
      // .addCase(getListScheduledTransactions.fulfilled, (state, action) => {
      //   state.loading.showScheduledTxnLoader = false;
      //   state.scheduledTransactions = action.payload;
      // })
      .addCase(transactionDetails.pending, (state) => {
        state.loading.transactionDetailLoader = true;
      })
      .addCase(transactionDetails.rejected, (state) => {
        state.loading.transactionDetailLoader = false;
      })
      .addCase(transactionDetails.fulfilled, (state, action) => {
        state.loading.transactionDetailLoader = false;
        state.transactionDetails = action.payload;
      })
      .addCase(sendMoney.pending, (state) => {
        state.loading.sendMoneyloader = true;
      })
      .addCase(sendMoney.rejected, (state) => {
        state.loading.sendMoneyloader = false;
      })
      .addCase(sendMoney.fulfilled, (state, action) => {
        state.loading.sendMoneyloader = false;
        state.paymentDet = action.payload;
      })
      .addCase(sendAirtime.pending, (state) => {
        state.loading.sendAirtimeLoader = true;
      })
      .addCase(sendAirtime.rejected, (state) => {
        state.loading.sendAirtimeLoader = false;
      })
      .addCase(sendAirtime.fulfilled, (state, action) => {
        state.loading.sendAirtimeLoader = false;
        state.paymentDet = action.payload;
      })
      .addCase(sendBillPayment.pending, (state) => {
        state.loading.sendBillLoader = true;
      })
      .addCase(sendBillPayment.rejected, (state) => {
        state.loading.sendBillLoader = false;
      })
      .addCase(sendBillPayment.fulfilled, (state, action) => {
        state.loading.sendBillLoader = false;
        state.paymentDet = action.payload;
      })
      .addCase(cancelScheduledTransaction.pending, (state) => {
        state.loading.cancelTransactionLoader = true;
      })
      .addCase(cancelScheduledTransaction.rejected, (state) => {
        state.loading.cancelTransactionLoader = false;
      })
      .addCase(cancelScheduledTransaction.fulfilled, (state, action) => {
        state.loading.cancelTransactionLoader = false;
        state.isCancelTxn = true;
      })
      .addCase(getTransactionFiles.pending, (state) => {
        state.loading.transactionFileDetailLoader = true;
      })
      .addCase(getTransactionFiles.rejected, (state) => {
        state.loading.transactionFileDetailLoader = false;
      })
      .addCase(getTransactionFiles.fulfilled, (state, action) => {
        state.loading.transactionFileDetailLoader = false;
        state.getTransactionFiles = action.payload;
      })
      .addCase(TransactionFilesUpload.pending, (state) => {
        state.loading.transactionFileUploadLoader = true;
      })
      .addCase(TransactionFilesUpload.rejected, (state) => {
        state.loading.transactionFileUploadLoader = false;
      })
      .addCase(TransactionFilesUpload.fulfilled, (state, action) => {
        state.loading.transactionFileUploadLoader = false;
        state.TransactionFilesUpload = action.payload;
      });
  },
});

export const {
  handlingAmountData,
  handlingReceiverData,
  handlingCollectionMode,
  resetAmountData,
  resetTransaction,
  resetCollectionMode,
  handleResendTransaction,
  handlingDirection,
  handleTransactionFromRecipient,
  selectedTopUpPack,
  setMoneyTransferCorridor,
  setMobileTopUpCorridor,
  setBillPaymentCorridor,
  handleRecurringTransaction,
  handleLaterTransaction,
  resetLaterTxnDetails,
  resetRecurringTxnDetails,
  clearPaymentDet,
  isScheduleTransaction,
  setPaymentMode,
} = transactionSlice.actions;

export default transactionSlice.reducer;
