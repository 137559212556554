import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Image,
  Heading,
  Flex,
  useDisclosure,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Link
} from "@chakra-ui/react";
import { logout } from "../../redux/modules/auth";
import {
  flushAccount,
  getProfile,
  getConfiguration
} from "../../redux/modules/account";
import SidebarMenuListitems from "../../components/layout/SidebarMenuListitems";
import { flushKYC } from "../../redux/modules/kyc";
import {
  resetAmountData,
  resetTransaction,
} from "../../redux/modules/transactions";
import { toastrMsg } from "../../utils/toastrMsg";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getCorridors } from "../../redux/modules/static";
import profileName from "../../utils/profileName";
import Notifications from "../overview/Notifications";
import mitoLogo from "../../assets/images/mito-logo.png";
import PersonaImage from "../../assets/images/Persona-Image _ 040.svg";
import capitalizeBusinessName from "../../utils/capitalizeBusinessName";
import capitalizeText from "../../utils/capitalizeText";

interface PropsTypes {
  childrens?: any;
  isSidebarCollapased?: any;
}

const Header: React.FC<PropsTypes> = () => {
  const { isSidebarCollapased, kycStarted, tenancyConfigData } = useAppSelector((state: any) => ({
    isSidebarCollapased: state.account.isSidebarCollapased,
    tenancyConfigData: state.affiliate.multiTenancyConfigData,
    kycStarted: state.kyc.kycStarted,
  }));
  const [user, setUser] = useState<any>({});
  const [logoImage, setLogoImage] = useState<any>("");
  let navigate = useNavigate();
  let dispatch = useAppDispatch();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  useEffect(() => {
    const getUserprofile = async () => {
      let res: any = await dispatch(getProfile());
      if (res.error) {
        toastrMsg("Error", res.error.message);
      } else {
        setUser(res.payload.data);
      }
    };
    getUserprofile();
  }, [dispatch]);
  
  useEffect(() => {
    const getConfig = async () => {
      const res: any = await dispatch(getConfiguration());
      if (res.error) {
        toastrMsg("Error", res.error.message);
      }
    };
    getConfig();
  }, [dispatch]);

  useEffect(() => {
    if(localStorage.getItem('affiliateId')) {
      dispatch(getCorridors());
    }
  }, [dispatch, user]);

  const logoutUser = () => {
    dispatch(logout());
    dispatch(flushAccount());
    dispatch(flushKYC());
    dispatch(resetTransaction());
    dispatch(resetAmountData());
    navigate("/");
  };
  
  useEffect(() => {
    //logo image 
    const getLogoIconData = tenancyConfigData?.logo;
    const headerimageUrl = `data:image/jpeg;base64,${getLogoIconData}`;
    setLogoImage(headerimageUrl);
    
  }, [tenancyConfigData?.logo])

  const handleClick = (referenceNumber: any) => {
    setPopoverOpen(false);
    navigate(`/support/my-tickets/${referenceNumber}`);
  };
  
  const handleBadgeClick = () => {
    setPopoverOpen(!isPopoverOpen);
  };

  return (
    <>
      <Flex
        pos="relative"
        position="fixed"
        width="100%"
        bg="white"
        display={{ sm: "none", lg: "flex", xl: "flex" }}
        borderBottom="2px solid"
        borderColor="#F1F5F7"
        py="18px"
        pl="37px"
        pr="42px"
        justify="space-between"
        align="center"
        zIndex="9999"
        style={{pointerEvents: kycStarted ? "none": 'auto'}}
        gap="6" alignItems={'center'}
      >
        <Flex gap="6" alignItems={'center'}>
          <Image 
            src={mitoLogo}
            onClick={() => navigate("/overview")}
            cursor="pointer"
            w="30px"
          />
          {!tenancyConfigData?.useLogoText ? (
            <Image
              w="30px"
              h="35px"
              src={logoImage}
              onClick={() => navigate("/")}
              cursor="pointer"
              alt=""
            />
          ) :
          (
            <Heading
              mt="3.5px"
              as="h4"
              cursor="pointer"
              size="xl"
              color="black"
              onClick={() => navigate("/")}
            >
              {tenancyConfigData?.logoAltText}
            </Heading>
          )}
        </Flex>
        <Box display="inline-flex" alignItems="center">
          <Flex gap="50" mr="10">
            <Heading
              as="p"
              size="lg_light"
              color="#323130"
              textTransform="initial"
            >
              <Link
                href='#'
                p="0.5rem 1rem"
              >
                How it works
              </Link>
              <Link
                href='#'
                p="0.5rem 1rem"
              >
                Help
              </Link>
            </Heading>
          </Flex>

          <Flex mr="3">
            <Notifications />
          </Flex>
          <Box>
            <Flex gap="10px" width="100%" justifyContent='space-around'>                
              <Flex direction="column" gap="5px" alignItems={'flex-end'}>
                {user.firstName && (
                  <Heading display={{sm: "none", lg:'inline-block' }} as="p" mr="8px" size="xl_light">
                    {user.isBusinessProfie
                    ? `${profileName(user.company?.businessName)}`
                    : `${profileName(user.firstName)} ${profileName(
                        user.lastName)}`
                  }
                  </Heading>
                )}
                <Heading display={{sm: "none", lg:'inline-block' }} as="p" mr="8px" size="md_light" color='#605E5C'>
                  {user.isBusinessProfie ? "Business" : "Individual"} Profile
                </Heading>
              </Flex>

              <Flex>
                {user.imageUrl ? 
                  <Image src={user.imageUrl} alt="" width="40px" height="41px" objectFit={'cover'} borderRadius={'50%'} />
                :
                  <>
                    {user.firstName && (
                      <>
                        {user.isBusinessProfie ? (
                          <Heading
                            display="inline-flex"
                            as="h5"
                            alignItems={'center'}
                            justifyContent={'center'}
                            size="lg_medium"
                            variant="white"
                            bg="blue.300"
                            py="5px"
                            px="6px"
                            mr="8px"
                            borderRadius="25px"
                            cursor="pointer"
                            minW="40px"
                            minH="40px"
                          >
                            {capitalizeBusinessName(user.company?.businessName)}
                          </Heading>
                        ) : (
                          <Heading
                            display="inline-flex"
                            as="h5"
                            alignItems={'center'}
                            justifyContent={'center'}
                            size="lg_medium"
                            variant="white"
                            bg="blue.300"
                            py="5px"
                            px="6px"
                            mr="8px"
                            borderRadius="25px"
                            cursor="pointer"
                            minW="40px"
                            minH="40px"
                          >
                            {capitalizeText(user.firstName)}
                            {capitalizeText(user.lastName)}
                          </Heading>
                        )}
                      </>
                    )}
                  </>
                }
              </Flex>
            </Flex>
          </Box> 
        </Box>
      </Flex>

      <Box
        display={{ sm: "block", lg: "none", xl: "none" }}
        transition="100ms ease"
        pos="sticky"
        top="0rem"
        zIndex={999}
        borderBottom="2px solid"
        borderColor="#F1F5F7"
        bg="white"
      >
        <Flex
          py="18px"
          pl="16px"
          pr="16px"
          justify="space-between"
          align="center"
        >
          <Flex align="center" gap="4" alignItems={'center'} justifyContent='space-between'>
            <Image 
              src={mitoLogo}
              onClick={() => navigate("/overview")}
              cursor="pointer"
              w="30px"
            />
            {!tenancyConfigData?.useLogoText ? (
              <Image
                w="30px"
                h="35px"
                src={logoImage}
                onClick={() => navigate("/overview")}
                cursor="pointer"
                alt="Logo"
              />
              ) :
              (
              <Heading
                mt="3.5px"
                as="h4"
                cursor="pointer"
                size="xl"
                color="black"
                onClick={() => navigate("/")}
              >
                {tenancyConfigData?.logoAltText}
              </Heading>
            )}
          </Flex>
          <Flex h="100%">
            <Button variant="transparent" onClick={onToggle} p="0">
              <Box id="spring" className="hamburger hamburger--spring" p="0">
                <Box className="hamburger-box">
                  <Box className="hamburger-inner"></Box>
                </Box>
              </Box>
            </Button>
            <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
              <DrawerOverlay />
              <DrawerContent backgroundColor="#fff">
                <DrawerBody onClick={onClose}>
                  <SidebarMenuListitems
                    isSidebarCollapased={isSidebarCollapased}
                  />
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </Flex>
          
        </Flex>
      </Box>
    </>
  );
};

export default Header;