import { Route } from "react-router-dom";
import  React  from 'react';

//Static
const Home = React.lazy(() => import('../pages/home'));
const Terms = React.lazy(() => import('../pages/terms'));
const Privacy = React.lazy(() => import('../pages/privacy'));
const Contact = React.lazy(() => import('../pages/contact'));
const AboutUs = React.lazy(() => import('../pages/aboutus'));
const FAQ = React.lazy(() => import('../pages/FAQ'));

//Auth
const Login = React.lazy(() => import('../pages/login/login'));
const SignUp = React.lazy(() => import('../pages/signup'));
const Activateaccount = React.lazy(() => import('../pages/activateaccount'));
const RegisterActivation = React.lazy(() => import('../pages/register-activation'));
const NotFound = React.lazy(() => import('../pages/notfound'));
const PasswordChange = React.lazy(() => import('../pages/passwordchange'));

//Overview
const Overview = React.lazy(() => import('../pages/overview'));
const CollectionAccount = React.lazy(() => import('../pages/overview/collectionaccount'));

//Supports
const Support = React.lazy(() => import('../pages/support'));
const SupportFaq = React.lazy(() => import('../pages/support/faq'));
const MyTickets = React.lazy(() => import('../pages/support/my-tickets'));
const MyTicketsDetails = React.lazy(() => import('../pages/support/my-tickets/[id]'));
const CreateTicket = React.lazy(() => import('../pages/support/create-ticket'));

//Settings
const Settings = React.lazy(() => import('../pages/settings'));

//Compliance
const Compliance = React.lazy(() => import('../pages/compliance'));

//Recipients
const Beneficiaries = React.lazy(() => import('../pages/beneficiaries'));
const BeneficiaryDetails = React.lazy(() => import('../pages/beneficiaries-details/[id]'));
const EditBeneficiary = React.lazy(() => import('../pages/beneficiaries/edit-beneficiary/[id]'));
const AddBeneficiaries = React.lazy(() => import('../pages/beneficiaries/add-beneficiaries'));

//NotFoundPage
const NotFoundPage = React.lazy(() => import('../pages/404'));

//Transactions
const Transactions = React.lazy(() => import('../pages/transactions'));
const TransactionDetails = React.lazy(() => import('../pages/transactions/transactiondetails/[id]'));
const TransactionsSendmoney = React.lazy(() => import('../pages/transactions/sendmoney'));
const TransactionsAirtimetopup = React.lazy(() => import('../pages/transactions/airtimetopup'));
const TransactionsBillpayment = React.lazy(() => import('../pages/transactions/billpayment'));


const Status = React.lazy(() => import('../pages/kyc/status'));

const Deactivated = React.lazy(() => import('../pages/domain-deactivated'));

export const publicRoutes = [
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/signup",
    element: <SignUp />
  },
  {
    path: "/activateaccount",
    element: <Activateaccount />
  },
  {
    path: "/register-activation",
    element: <RegisterActivation />
  },
  {
    path: "/notfound",
    element: <NotFound />
  },
  {
    path: "/passwordchange",
    element: <PasswordChange />
  },
  {
    path: "/terms",
    element: <Terms />
  },
  {
    path: "/privacy",
    element: <Privacy />
  },
  {
    path: "/contact",
    element: <Contact />
  },
  {
    path: "/aboutus",
    element: <AboutUs />
  },
  {
    path: "/FAQ",
    element: <FAQ />
  },
  {
    path: "*",
    element: <NotFoundPage />
  },
  {
    path: "/kyc/status",
    element: <Status />
  },
  {
    path: "/domain-deactivated",
    element: <Deactivated />
  },
].map((props, index) => <Route key={props.path || index} {...props} />);

export const privateRoutes = [
  {
    path: "/Overview",
    element: <Overview />
  },
  {
    path: "/support",
    element: <Support />
  },
  {
    path: "/support/faqs",
    element: <SupportFaq />
  },
  {
    path: "/support/my-tickets",
    element: <MyTickets />
  },
  {
    path: "/support/my-tickets/:id",
    element: <MyTicketsDetails />
  },
  {
    path: "/support/create-ticket",
    element: <CreateTicket />
  },
  {
    path: "/settings",
    element: <Settings />
  },
  {
    path: "/compliance",
    element: <Compliance />
  },
  {
    path: "/beneficiaries",
    element: <Beneficiaries />
  },
  {
    path: "/beneficiaries-details/:id",
    element: <BeneficiaryDetails />
  },
  {
    path: "/beneficiaries/add-beneficiaries",
    element: <AddBeneficiaries />
  },
  {
    path: "/beneficiaries/edit-beneficiary/:id",
    element: <EditBeneficiary />
  },
  {
    path: "/overview/collectionaccount",
    element: <CollectionAccount />
  },
  {
    path: "/transactions",
    element: <Transactions />
  },
  {
    path: "/transactions/transactiondetails/:id",
    element: <TransactionDetails />
  },
  {
    path: "/transactions/sendmoney",
    element: <TransactionsSendmoney />
  },
  {
    path: "/transactions/airtimetopup",
    element: <TransactionsAirtimetopup />
  },
  {
    path: "/transactions/billpayment",
    element: <TransactionsBillpayment />
  },
].map((props, index) => <Route key={props.path || index} {...props} />);