import { ComponentMultiStyleConfig } from "@chakra-ui/react";

const Menu: ComponentMultiStyleConfig = {
	parts: ["button", "list", "item"],

	variants: {
		profile: {
			button: {
				color: "black.150",
				fontSize: "lg",
				fontWeight: "normal",
				lineHeight: "16px",
				zIndex: "99",
			},
			list: {
				bg: "white ",
				zIndex: "99",
				fontSize: "md",
				color: "black.150",
			},
		},
		Icon: {
			button: {
				background: "transparent",
			},
			list: {
				boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
				borderRadius: "2px",
				border: "0px",
				px: "10px",
				pt: "10px",
				zIndex: "999 !important",
				opacity: "1 !important",
				bgColor: "white",
			},
			item: {
				opacity: "1 !important",
				zIndex: "999 !important",
				fontSize: "md",
				color: "secondary",
				fontWeight: "light",
				_focus: {
					bg: "transparent",
				},
			},
		},
		filled: {
			button: {
				bg: "purple.400",
				fontSize: "md",
				fontWeight: "light",
				lineHeight: "16px",
				color: "white",
				borderRadius: "2px",
				px: "12px",
				height: "32px",
			},
			list: {
				boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
				border: "0px",
				borderRadius: "2px",
			},
			item: {
				color: "black.100",
				fontSize: "md",
				fontWeight: "light",
				lineHeight: "16px",
			},
		},
	},
};

export default Menu;
