import type { ComponentSingleStyleConfig } from "@chakra-ui/react";
const Button: ComponentSingleStyleConfig = {
  baseStyle: {
    height: { sm: "40px", lg: "32px", xl: "40px" },
		bg: 'var(--clr1)',
    color: "white",
    px: "16px",
    _focus: {
      boxShadow: "0px",
    },
		_disabled: {
			cursor: "not-allowed",
			opacity: ".4",
		},
  },
  sizes: {
    lg: {
      fontSize: "lg",
      fontWeight: "medium",
      lineHeight: "20px",
    },
    lg_light: {
      fontSize: "lg",
      fontWeight: "light",
      lineHeight: "20px",
    },
    large: {
      fontSize: { sm: "lg", lg: "md", xl: "lg" },
      fontWeight: { sm: "medium", lg: "normal", xl: "medium" },
      lineHeight: "20px",
    },
  },
  variants: {
    solidBlue: {
      bg: 'var(--clr1)',
      borderRadius: "2px",
    },
    solid_lightBlue: {
      bg: 'var(--clr1)',
      borderRadius: "2px",
      _hover: {
        _disabled: {
          bg: 'var(--clr1)',
          opacity: "0.7",
        },
      },
      _disabled: {
        opacity: "0.7",
      },
    },
    Solid_gray: {
      bg: 'var(--clr1)',
      border: "1px",
      borderColor: "#A19F9D",
      borderRadius: "2px",
      color: "#605E5C",
    },
    Solid_disabled: {
      bg: "#EDEBE9",
      color: "#A19F9D",
    },
    Solid_outline: {
      bg: "#fff",
      color: "#A00F0F",
      border: "1px solid",
      borderColor: "#A00F0F",
      transition: "0.1s ease-out",
    },
    Solid_outline_primary: {
      bg: "white",
      color: 'var(--clr1)',
      border: "1px solid",
      borderColor: 'var(--clr1)',
      _hover: {
        bg: 'var(--clr1)',
        color: "white",
        transition: "0.1s ease-in",
      },
    },
    Solid_red: {
      bg: "#ffd1df",
      color: "#c2185c",
      border: "1px solid",
      borderColor: 'var(--clr1)',
      _hover: {
        bg: "#c2185c",
        color: "white",
        transition: "0.1s ease-in",
      },
    },
    Solid_red_error: {
      bg: "#ff0000",
      color: "#fff",
      border: "1px solid",
      borderColor: "#ff0000",
      _hover: {
        bg: "#ff0000",
        color: "white",
        transition: "0.1s ease-in",
      },
    },
    rounded_edges: {
      bg: 'var(--clr1)',
      borderRadius: "20px",
    },
    transparent: {
      bg: "transparent",
      color: "black",
      fontSize: "sm",
      fontWeight: "bold",
      transition: "0.1s ease",
      _hover: {
        color: "green.500",
        transition: "0.1s ease",
      },
    },
    boxShadow: {
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      borderRadius: "8px",
      bg: "white.900",
      color: "black.900",
      _disabled: {
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      },
    },
  },
};

export default Button;
