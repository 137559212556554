import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import api from "../../utils/apiClient";
import { getCountriesByServiceCode } from "../../redux/modules/static";

export interface AuthState {
	user: any;
	userData: any;
	email: any;
	userEmail: any;
	loginLoading: boolean;
	loading: any;
	signUpData: any;
	signUpFinalData: any;
	userLoaded: boolean;
	setResetPSWshow: boolean;
	clearInputfields: boolean;
}

const initialState: AuthState = {
	user: "",
	userData: {},
	email: {},
	userEmail: "",
	loginLoading: false,
	loading: {
		loginLoading: false,
		signUpLoading: false,
		confirmResetpasswordLoading: false,
		overlayLoader: false,
		accountLoader: true,
		updateBusinessUserLoading: false,
		UpdateBusinessProfileLoading: false,
	},
	signUpData: {
		data: { step: "step_1" },
	},
	signUpFinalData: {},
	userLoaded: false,
	setResetPSWshow: false,
	clearInputfields: false,
};

export const signUp = createAsyncThunk("Auth/register", async (data: any) => {
	const response = await api.post("Auth/register", {
		data,
	});
	return response;
});

export const getSearchDirectorList = createAsyncThunk(
	"Auth/GetSearchDirectorList",
	async (data: any) => {
		const response = await api.get(
			`Auth/GetSearchDirectorList?countryIso3=${data.countryIso3}&regNo=${data.companyNumber}`
		);
		return response;
	}
);

export const login = createAsyncThunk("Auth/login", async (data: any) => {
	const response = await api.post("Auth/login", { data });
	if(response.data.token){
		localStorage.setItem("authToken", response.data.token);
		localStorage.setItem("authUser", JSON.stringify(response.data));
		Cookies.set("authToken", response.data.token, { expires: 8 / 1440 });
	}	
	return response;
});

export const activateAccount = createAsyncThunk(
	"Auth/activate",
	async (data: any) => {
		const response = await api.get(
			`Auth/activate?activationToken=${encodeURIComponent(data)}`
		);
		return response;
	}
);

export const forgetPassword = createAsyncThunk(
	"Auth/ResetPassword",
	async (data: any) => {
		const response = await api.post("Auth/ResetPassword", {
			data,
		});
		return response;
	}
);

export const validateResetPassword = createAsyncThunk(
	"Auth/ValidateResetPassword",
	async (data: any) => {
		const response = await api.get(
			`Auth/ValidateResetPassword?passwordReminderActivationToken=${encodeURIComponent(
				data
			)}`
		);
		return response;
	}
);

export const confirmResetpassword = createAsyncThunk(
	"Auth/ConfirmResetPassword",
	async (data: any) => {
		const response = await api.post("Auth/ConfirmResetPassword", {
			data,
		});
		return response;
	}
);

export const resentActivate = createAsyncThunk(
	"Auth/ReSentActivate",
	async (data: any) => {
		const response = await api.get(`Auth/ReSentActivate?email=${data}`);
		return response;
	}
);

export const updateBusinessUser = createAsyncThunk(
	"Auth/UpdateBusinessUser",
	async (data: any) => {
		const response = await api.post("Auth/UpdateBusinessUser", {
			data,
		});
		return response;
	}
);

export const UpdateBusinessProfile = createAsyncThunk(
	"Auth/UpdateBusinessProfile",
	async (data: any) => {
		const response = await api.post("Auth/UpdateBusinessProfile", {
			data,
		});
		return response;
	}
);


export const AuthSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		load: (state) => {
			state.user = localStorage.getItem("authToken");
			state.userData = JSON.parse(localStorage.getItem("authUser") || "");
			state.userLoaded = true;
		},
		logout: (state) => {
			function clearLocalStorageExcept(excludedKeys: string[]) {
				for (let key in localStorage) {
				  if (localStorage.hasOwnProperty(key) && !excludedKeys.includes(key)) {
					localStorage.removeItem(key);
				  }
				}
			}
			localStorage.removeItem("authToken");
			localStorage.removeItem("authUser");
			clearLocalStorageExcept(['RH_COOKIE_DATA', 'affiliateId']);
			Cookies.remove("authToken");
			state.user = "";
			state.userLoaded = false;
			state.userData = {};
			state.userEmail = "";
		},
		handleEmail: (state, action) => {
			state.userEmail = action.payload;
		},
		resetPasswordemail: (state) => {
			state.userEmail = JSON.parse(localStorage.getItem("userEmail") || "");
		},
		resetInputfields: (state) => {
			state.clearInputfields = true;
		},
		showResetfields: (state) => {
			state.setResetPSWshow = true;
		},
		handlingSignUp: (state, action) => {
			state.signUpData.data = action.payload;
		},
		handlingSignUpFinalData: (state, action) => {
			state.signUpFinalData = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(login.pending, (state, action) => {
				state.loading.loginLoading = true;
			})
			.addCase(login.rejected, (state, action) => {
				state.loading.loginLoading = false;
			})
			.addCase(login.fulfilled, (state, action) => {
				state.loading.loginLoading = false;
			})
			.addCase(signUp.pending, (state) => {
				state.loading.signUpLoading = true;
			})
			.addCase(signUp.rejected, (state) => {
				state.loading.signUpLoading = false;
			})
			.addCase(signUp.fulfilled, (state) => {
				state.loading.signUpLoading = false;
			})
			.addCase(getSearchDirectorList.pending, (state) => {
				state.loading.signUpLoading = true;
			})
			.addCase(getSearchDirectorList.rejected, (state) => {
				state.loading.signUpLoading = false;
			})
			.addCase(getSearchDirectorList.fulfilled, (state) => {
				state.loading.signUpLoading = false;
			})
			.addCase(confirmResetpassword.pending, (state) => {
				state.loading.confirmResetpasswordLoading = true;
			})
			.addCase(confirmResetpassword.rejected, (state) => {
				state.loading.confirmResetpasswordLoading = false;
			})
			.addCase(confirmResetpassword.fulfilled, (state) => {
				state.loading.confirmResetpasswordLoading = false;
			})

			.addCase(getCountriesByServiceCode.pending, (state) => {
				state.loading.overlayLoader = true;
			})
			.addCase(getCountriesByServiceCode.rejected, (state) => {
				state.loading.overlayLoader = false;
			})
			.addCase(getCountriesByServiceCode.fulfilled, (state) => {
				state.loading.overlayLoader = false;
			})
			.addCase(activateAccount.pending, (state) => {
				state.loading.accountLoader = true;
			})
			.addCase(activateAccount.rejected, (state) => {
				state.loading.accountLoader = false;
			})
			.addCase(activateAccount.fulfilled, (state) => {
				state.loading.accountLoader = false;
			})
			.addCase(updateBusinessUser.pending, (state) => {
				state.loading.updateBusinessUserLoading = true;
			})
			.addCase(updateBusinessUser.rejected, (state) => {
				state.loading.updateBusinessUserLoading = false;
			})
			.addCase(updateBusinessUser.fulfilled, (state) => {
				state.loading.updateBusinessUserLoading = false;
			})
			.addCase(UpdateBusinessProfile.pending, (state) => {
				state.loading.UpdateBusinessProfileLoading = true;
			})
			.addCase(UpdateBusinessProfile.rejected, (state) => {
				state.loading.UpdateBusinessProfileLoading = false;
			})
			.addCase(UpdateBusinessProfile.fulfilled, (state) => {
				state.loading.UpdateBusinessProfileLoading = false;
			});
	},
});

export const {
	load,
	logout,
	showResetfields,
	resetInputfields,
	handlingSignUp,
	handlingSignUpFinalData,
	handleEmail,
} = AuthSlice.actions;

export default AuthSlice.reducer;
