import { Flex, Spinner } from "@chakra-ui/react";

const Loading = () => (
  <Flex justifyContent="center">
    <Flex justify="center" position={{sm: "fixed", lg: "absolute"}} top="50%" left="50%" zIndex={2}>
      {/* <Box pos="absolute" top="40%" className="spinner">
						<Box className="spinner-item"></Box>
						<Box className="spinner-item"></Box>
						<Box className="spinner-item"></Box>
						<Box className="spinner-item"></Box>
						<Box className="spinner-item"></Box>
					</Box> */}
      <Spinner color="blue.400" w="30px" h="30px" />
    </Flex>
  </Flex>
);

export default Loading;
