import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

const List: ComponentMultiStyleConfig = {
	parts: ["container", "item"],
	baseStyle: {},
	variants: {
		HorizontalBar: {
			container: {
				display: "flex",
				justifyContent: "space-between",
			},
			item: {
				color: "black.100",
				fontWeight: "medium",
				lineHeight: "20px",
				listStyle: "none",
				fontSize: "lg",
				textTransform: "capitalize",
				fontStyle: "normal",
			},
		},
		HorizontalBarLight: {
			container: {
				display: "flex",
				justifyContent: "space-between",
				position: "relative",
				borderBottom: "1px",
				borderColor: "#E1DFDD",
				px: "28px",
				py: "9px",
			},
			item: {
				color: "#605E5C",
				fontWeight: "light",
				fontSize: "md",
				textTransform: "capitalize",
				fontStyle: "normal",
			},
		},
		SideBar: {
			item: {
				display: "inline-flex",
				gap: "20px",
				w: "100%",
				mb: "28px",
				fontSize: { sm: "lg", lg: "md", xl: "lg" },
				fontWeight: "light",
				lineHeight: { sm: "20px", lg: "16px", xl: "20px" },
				color: "#B7B7B7",
				_hover: {
					color: 'var(--clr1)',
					cursor: "pointer",
				},
				_selected: {
					color: "#2E5BFF",
				},
			},
			icon: {
				color: "#B7B7B7",
				fontSize: "xl",
				_groupHover: {
					color: 'var(--clr1)',
					transition: " 0.3s ease",
					cursor: "pointer",
				},
			},
		},
		Privacy: {
			item: {
				paddingTop: "20px",
				fontSize: "lg",
				color: "rgb(155, 155, 155)",
			},
		},
	},
};

export default List;
