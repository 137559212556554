import { ComponentSingleStyleConfig } from "@chakra-ui/react";

const Heading: ComponentSingleStyleConfig = {
	baseStyle: {
		textTransform: "capitalize",
	},

	sizes: {
		xs_light: {
			fontWeight: "light",
			fontSize: "xs",
			lineHeight: "12px",
		},
		xs_normal: {
			fontWeight: "normal",
			fontSize: "xs",
			lineHeight: "14px",
		},
		xs_medium: {
			fontWeight: "medium",
			fontSize: "xs",
			lineHeight: "14px",
		},
		sm_light: {
			fontWeight: "light",
			fontSize: "sm",
			lineHeight: "14px",
		},
		sm_normal: {
			fontWeight: "normal",
			fontSize: "sm",
			lineHeight: "16px",
		},
		sm_medium: {
			fontWeight: "medium",
			fontSize: "sm",
			lineHeight: "16px",
		},
		md_light: {
			fontWeight: "light",
			fontSize: "md",
			lineHeight: "16px",
		},
		md_normal: {
			fontWeight: "normal",
			fontSize: "md",
			lineHeight: "16px",
		},
		md_medium: {
			fontWeight: "medium",
			fontSize: "md",
			lineHeight: "16px",
		},
		lg_light: {
			fontWeight: "light",
			fontSize: "lg",
			lineHeight: "20px",
		},
		lg_normal: {
			fontWeight: "normal",
			fontSize: "lg",
			lineHeight: "20px",
		},
		lg_medium: {
			fontWeight: "medium",
			fontSize: "lg",
			lineHeight: "20px",
		},
		lg_bold: {
			fontWeight: "bold",
			fontSize: "lg",
			lineHeight: "20px",
		},
		xl_light: {
			fontWeight: "light ",
			fontSize: "xl",
			lineHeight: "20px",
		},
		xl_normal: {
			fontWeight: "normal",
			fontSize: "xl",
			lineHeight: "20px",
		},
		xl_medium: {
			fontWeight: "medium",
			fontSize: "xl",
			lineHeight: "20px",
		},
		xxl_light: {
			fontWeight: "light",
			fontSize: "xxl",
			lineHeight: "24px",
		},
		xxl_normal: {
			fontWeight: "normal",
			fontSize: "xxl",
			lineHeight: "24px",
		},
		xxl_medium: {
			fontWeight: "medium",
			fontSize: "xxl",
			lineHeight: "24px",
		},
		xxl_bold: {
			fontWeight: "bold",
			fontSize: "xxl",
			lineHeight: "24px",
		},
		xxxl_light: {
			fontWeight: "light",
			fontSize: "xxxl",
			lineHeight: "28px",
		},
		xxxl_normal: {
			fontWeight: "normal",
			fontSize: "xxxl",
			lineHeight: "28px",
		},
		xxxl_medium: {
			fontWeight: "medium",
			fontSize: "xxxl",
			lineHeight: "28px",
		},
		xxxl_bold: {
			fontWeight: "bold",
			fontSize: "xxxl",
			lineHeight: "28px",
		},
		xxxxl_light: {
			fontWeight: "light",
			fontSize: "6xl",
			lineHeight: "28px",
		},
		xxxxl_normal: {
			fontWeight: "normal",
			fontSize: "6xl",
			lineHeight: "28px",
		},
		xxxxl_medium: {
			fontWeight: "medium",
			fontSize: "6xl",
			lineHeight: "28px",
		},
		xxxxl_bold: {
			fontWeight: "bold",
			fontSize: "6xl",
			lineHeight: "28px",
		},
		Enlarge_light: {
			fontWeight: "light",
			fontSize: "Enlarge",
			lineHeight: "44px",
		},
		Enlarge_medium: {
			fontWeight: "medium",
			fontSize: "Enlarge",
			lineHeight: "44px",
		},
		LaptopFontMd_medium: {
			fontWeight: "medium",
			fontSize: "LaptopFontMd",
			lineHeight: "44px",
		},
		Laptop_Font_normal: {
			fontWeight: "normal",
			fontSize: "LaptopFontMd",
			lineHeight: "50px",
		},
		Laptop_Font: {
			fontWeight: "semibold",
			fontSize: "LaptopFont",
			lineHeight: "50px",
		},
		Banner_bold: {
			fontWeight: "bold",
			fontSize: "Banner",
			lineHeight: "70px",
		},
	},

	variants: {
		primary: {
			color: "black.200",
		},
		secondary: {
			color: "black.100",
		},
		grey: {
			color: "#201F1E",
		},
		disable: {
			color: "grey.100",
		},

		black: {
			color: "black.900",
		},
		disabled: {
			color: "#A19F9D",
		},
		white: {
			color: "white.900",
		},
		purple_light: {
			color: "purple.400",
		},
		purple_mid: {
			color: "purple.500",
		},
		blue_light: {
			color: "blue.300",
		},
		blue_mid: {
			color: "blue.400",
		},
		green_mid: {
			color: "green.500",
		},
		_error: {
			color: "red",
		},
	},
};

export default Heading;
