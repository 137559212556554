import { Box, SkeletonText } from "@chakra-ui/react";
import React from "react";

interface SkeletonLoaderProps {
  lines?: number;
  height?: number | string;
}

const defaultProps: SkeletonLoaderProps = { lines: 4, height: 5 };

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({
  lines,
  height,
} = defaultProps) => {
  return (
    <Box padding="6" boxShadow="lg" bg="white">
      <SkeletonText
        mt="4"
        noOfLines={lines}
        spacing="4"
        skeletonHeight={height}
      />
    </Box>
  );
};

export default SkeletonLoader;
