import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { reducer as toastrReducer } from "react-redux-toastr";

import auth from "../redux/modules/auth";
import beneficiaryReducer from "../redux/modules/beneficiary";
import accountReducer from "../redux/modules/account";
import transactionReducer from "../redux/modules/transactions";
import kycReducer from "../redux/modules/kyc";
import staticReducer from "../redux/modules/static";
import rateReducer from "../redux/modules/rate";
import cookie from "./modules/cookie";
import AffiliateReducer from "./modules/multiTenancyConfig";

export const store = configureStore({
  reducer: {
    toastr: toastrReducer,
    auth: auth,
    beneficiary: beneficiaryReducer,
    account: accountReducer,
    transaction: transactionReducer,
    static: staticReducer,
    kyc: kycReducer,
    rate: rateReducer,
    affiliate: AffiliateReducer,
    cookie: cookie,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
