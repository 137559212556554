import { ComponentMultiStyleConfig } from "@chakra-ui/react";

const Tabs: ComponentMultiStyleConfig = {
	parts: ["root", "tablist", "tab", "tabpanel"],

	variants: {
		payment: {
			tablist: {
				border: "0px",
			},
			tab: {
				bg: "#F3F2F1",
				py: "6px",
				px: "16px",
				fontSize: "lg",
				fontWeight: "light",
				borderRadius: "25px",
				color: "#000000",
				_hover: {
					color: "#ffffff",
					bg: "#3D48D1",
				},
				_focus: {
					boxShadow: "none",
				},
				_selected: {
					color: "#ffffff",
					bg: "#3D48D1",
				},
			},
		},
		bank_detail: {
			tab: {
				bg: "white",

				_focus: {
					boxShadow: "rgb(37 31 31 / 18%) 0px 8px 24px",
				},
				_selected: {
					boxShadow: "none",
					borderBottom: "2px solid #A19F9D" 
				},
			},
			tabpanel: {
				boxShadow: "rgba(0, 0, 0, 0.04) 0px 7px 29px 0px ",
				py: "24px",
				px: { sm: "0", lg: "24px" },
			},
		},
		country: {
			root: {
				display: "flex",
				flexDirection: { sm: "column", lg: "row", xl: "row" },
			},
			tab: {
				bg: "white",
				pos: "relative",
				px: "0px",
				justifyContent: "space-between",
				flexDir: "column",
				_focus: {
					boxShadow: "none",
				},
				_selected: {
					pos: "relative",
				},
			},
			tabpanel: {
				bg: "blue.50",
				pt: "16px",
				pl: "20px",
				w: "100%",
				borderRadius: "8px",
			},
		},
		setting: {
			tab: {
				fontSize: "lg",
				fontWeight: "light",
				color: "black.900",
				_selected: {
					borderBottom: "1px solid",
					borderColor: "blue.400",
				},
				_focus: {
					boxShadow: "none",
				},
			},
		},
	},
};

export default Tabs;
