import Loading from "./Loading";
import RelativeLoader from "./RelativeLoader";
import RelativeLoaderOutlet from "./RelativeLoaderOutlet";
import SkeletonCircularLoader from "./SkeletonCircularLoader";
import SkeletonLoader from "./SkeletonLoader";
import SkeletonOne from "./SkeletonOne";
import SkeletonTwo from "./AccountSummarySkeleton";
import SkeletonThree from "./ServicesSkeleton";

export default Loading;
export {
	RelativeLoader,
	RelativeLoaderOutlet,
	SkeletonCircularLoader,
	SkeletonLoader,
	SkeletonOne,
	SkeletonTwo,
	SkeletonThree,
};
