import { Box, SkeletonText } from "@chakra-ui/react";

const AccountSummarySkeleton = () => {
  return (
    <Box padding="6" boxShadow="lg" bg="white">
      <SkeletonText mt="4" noOfLines={2} spacing="4" />
    </Box>
  );
};

export default AccountSummarySkeleton;
