import { ComponentMultiStyleConfig } from "@chakra-ui/react";

const Radio: ComponentMultiStyleConfig = {
	parts: ["label", "control"],

	variants: {
		simpleRadio: {
			control: {
				bg: "white.900",
				w: "20px",
				h: "20px",
				border: "2px solid",
				borderColor: "purple.400",
				_checked: {
					bg: "white.900",
					color: "purple.400",
					borderColor: "purple.400",

					_hover: {
						bg: "transparent",
						borderColor: "purple.400",
					},
					_focus: {
						boxShadow: "none",
						borderColor: "purple.400",
					},
				},
			},
			label: {
				fontWeight: { sm: "normal", xl: "light" },
				fontSize: { sm: "md", xl: "lg" },
				lineHeight: "20px",
				pl: "8px",
			},
		},
	},
};

export default Radio;
