import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { take, orderBy } from "lodash";

import api from "../../utils/apiClient";

export interface AccountState {
  loading: any;
  user: any;
  userData: any;
  userBusinessData: any;
  userLoaded: boolean;
  overview: any;
  userAccountno: any;
  transactions: any[];
  scheduleTransactions: any[];
  beneficiaries: any;
  walletBalance: any;
  collectionBalance: any;
  summary: any;
  getOverviewLoading: boolean;
  getBalanceLoading: boolean;
  isBusinessProfie: boolean;
  businessName: any;
  companyRegNo: any;
  config: any;
  feedback: any;
  DeleteCard: any;
  TicketsData: any;
  UpdateTicketsData: any;
  TicketDetails: any;
  Faqs: any;
  Notifications: any;
  isSidebarCollapased: boolean;
}

const initialState: AccountState = {
  loading: {
    changePasswordLoading: false,
    getConfigurationLoading: false,
    feedbackLoader: false,
    getProfileLoading: false,
  },
  getOverviewLoading: false,
  getBalanceLoading: false,
  user: "",
  userData: {},
  userBusinessData: {},
  userLoaded: false,
  overview: {},
  userAccountno: "",
  transactions: [],
  scheduleTransactions: [],
  beneficiaries: [],
  walletBalance: [],
  collectionBalance: [],
  summary: [],
  isBusinessProfie: false,
  businessName: "",
  companyRegNo: "",
  config: {},
  feedback: {},
  TicketsData: {},
  UpdateTicketsData: {},
  TicketDetails: {},
  Faqs: {},
  Notifications: {},
  DeleteCard: "",
  isSidebarCollapased: true,
};

export const getProfile = createAsyncThunk("Account/profile", async () => {
  const response = await api.get("Account/profile");
  return response;
});

export const getProfileBusiness = createAsyncThunk("Account/GetProfileBusiness", async () => {
  const response = await api.get("Account/GetProfileBusiness");
  return response;
});

export const getOverview = createAsyncThunk("Account/Overview", async () => {
  const response = await api.get("Account/Overview");
  return response.data;
});

export const getBalance = createAsyncThunk("/Account/Balances", async () => {
  const response = await api.get("Account/Balances");
  return response.data;
});

export const changePassword = createAsyncThunk(
  "Auth/ChangePassword",
  async (data: any) => {
    const response = await api.post("Auth/ChangePassword", { data });
    return response;
  }
);

export const getConfiguration = createAsyncThunk(
  "Account/Configuration",
  async () => {
    const response = await api.get("Account/Configuration");
    return response.data;
  }
);

export const postFeedback = createAsyncThunk(
  "Account/RhemitoUserFeedBack",
  async (data: any) => {
    const response = await api.post("Account/RhemitoUserFeedback", { data });
    return response.data;
  }
);

export const DeleteUserCard = createAsyncThunk(
  "Payment/DeleteUserCard",
  async (data: any) => {    
    const response = await api.post('Payment/DeleteUserCard', { data });
    return response.data;
  }
);

export const SupportTicket = createAsyncThunk(
  "Account/SupportTicket",
  async (data: any) => {    
    const response = await api.post('Account/SupportTicket', { data }, {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  }
);

export const GetSupportTickets = createAsyncThunk(
  "Account/GetSupportTickets",
  async () => {    
    const response = await api.get('Account/GetSupportTickets');
    return response.data;
  }
);

export const GetFaqs = createAsyncThunk(
  "Account/Faqs",
  async () => {    
    const response = await api.get('Account/Faqs');
    return response.data;
  }
);

export const GetSupportTicketDetails = createAsyncThunk(
  "Account/GetSupportTicket",
  async (id: any) => {    
    const response = await api.get(`Account/GetSupportTicket?referenceNumber=${ id }`);
    return response.data;
  }
);

export const UpdateSupportTicket = createAsyncThunk(
  "Account/UpdateTicket",
  async (data: any) => {    
    const response = await api.post('Account/UpdateTicket', { data }, {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  }
);

export const GetNotifications = createAsyncThunk(
  "Account/GetNotifications",
  async () => {    
    const response = await api.get('Account/GetNotifications');
    return response.data;
  }
);


export const MarkNotificationRead = createAsyncThunk(
  "Account/MarkNotificationRead",
  async (id: any) => {
    const response = await api.get(`Account/MarkNotificationRead?referenceNumber=${ id }`);
    return response.data;
  }
);

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    flushAccount: (state) => {
      state.userLoaded = false;
      state.userData = {};
      state.userAccountno = "";
      state.overview = [];
      state.walletBalance = [];
      state.collectionBalance = [];
      state.transactions = [];
      state.scheduleTransactions = [];
      state.beneficiaries = [];
      state.isBusinessProfie = false;
      state.businessName = "";
      state.companyRegNo = "";
      state.config = {};
      state.feedback = {};
    },
    setSidebarCollapse: (state, action) => {
      state.isSidebarCollapased = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.loading.getProfileLoading = true;
      })
      .addCase(getProfile.rejected, (state) => {
        state.loading.getProfileLoading = false;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.loading.getProfileLoading = false;
        state.userData = action.payload.data;
        state.userAccountno = state.userData.accountNumber;
        state.isBusinessProfie = state.userData.isBusinessProfie;
        state.businessName = state.userData.businessName;
        state.companyRegNo = state.userData.companyRegNo;
      })
      .addCase(getOverview.pending, (state) => {
        state.getOverviewLoading = true;
      })
      .addCase(getOverview.rejected, (state) => {
        state.getOverviewLoading = false;
      })
      .addCase(getOverview.fulfilled, (state, action) => {
        state.getOverviewLoading = false;
        state.overview = action.payload;
        state.transactions = state.overview.transactions;
        state.scheduleTransactions = state.overview.scheduledTransactionGroupResponse.data;
        let response = take(
          orderBy(state.overview.recipients, [(o) => o.dateCreated], ["desc"]),
          4
        );
        state.beneficiaries = response;
        state.summary = state.overview.summary;
      })
      .addCase(getBalance.pending, (state) => {
        state.getBalanceLoading = true;
      })
      .addCase(getBalance.rejected, (state) => {
        state.getBalanceLoading = false;
      })
      .addCase(getBalance.fulfilled, (state, action) => {
        state.getBalanceLoading = false;
        state.walletBalance = action.payload.wallet;
        state.collectionBalance = action.payload.collectionAccount;
      })

      .addCase(changePassword.pending, (state) => {
        state.loading.changePasswordLoading = true;
      })
      .addCase(changePassword.rejected, (state) => {
        state.loading.changePasswordLoading = false;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.loading.changePasswordLoading = false;
      })

      .addCase(getConfiguration.pending, (state) => {
        state.loading.getConfigurationLoading = true;
      })
      .addCase(getConfiguration.rejected, (state) => {
        state.loading.getConfigurationLoading = false;
      })
      .addCase(getConfiguration.fulfilled, (state, action) => {
        state.loading.getConfigurationLoading = false;
        state.config = action.payload;
      })
      .addCase(postFeedback.pending, (state) => {
        state.loading.feedbackLoader = true;
      })
      .addCase(postFeedback.rejected, (state) => {
        state.loading.feedbackLoader = false;
      })
      .addCase(postFeedback.fulfilled, (state, action: any) => {
        state.loading.feedbackLoader = false;
        state.feedback = action.payload;
      })
      .addCase(getProfileBusiness.pending, (state) => {
        state.loading.getProfileBusinessLoading = true;
      })
      .addCase(getProfileBusiness.rejected, (state) => {
        state.loading.getProfileBusinessLoading = false;
      })
      .addCase(getProfileBusiness.fulfilled, (state, action) => {
        state.loading.getProfileBusinessLoading = false;
        state.userBusinessData = action.payload.data;
        state.userAccountno = state.userData.accountNumber;
        state.isBusinessProfie = state.userData.isBusinessProfie;
        state.businessName = state.userData.businessName;
        state.companyRegNo = state.userData.companyRegNo;
      })
      .addCase(DeleteUserCard.pending, (state) => {
        state.loading.deleteUserCardLoader = true;
      })
      .addCase(DeleteUserCard.rejected, (state) => {
        state.loading.deleteUserCardLoader = false;
      })
      .addCase(DeleteUserCard.fulfilled, (state, action: any) => {
        state.loading.deleteUserCardLoader = false;
      })
      .addCase(SupportTicket.pending, (state) => {
        state.loading.SupportTicketLoader = true;
      })
      .addCase(SupportTicket.rejected, (state) => {
        state.loading.SupportTicketLoader = false;
      })
      .addCase(SupportTicket.fulfilled, (state, action: any) => {
        state.loading.SupportTicketLoader = false;
      })
      .addCase(GetSupportTickets.pending, (state) => {
        state.loading.GetSupportTicketsLoader = true;
      })
      .addCase(GetSupportTickets.rejected, (state) => {
        state.loading.GetSupportTicketsLoader = false;
      })
      .addCase(GetSupportTickets.fulfilled, (state, action: any) => {
        state.loading.GetSupportTicketsLoader = false;
        state.TicketsData = action.payload;
      })
      .addCase(UpdateSupportTicket.pending, (state) => {
        state.loading.UpdateSupportTicketLoader = true;
      })
      .addCase(UpdateSupportTicket.rejected, (state) => {
        state.loading.UpdateSupportTicketLoader = false;
      })
      .addCase(UpdateSupportTicket.fulfilled, (state, action: any) => {
        state.loading.UpdateSupportTicketLoader = false;
        state.UpdateTicketsData = action.payload;
      })
      .addCase(GetSupportTicketDetails.pending, (state) => {
        state.loading.GetSupportTicketDetailsLoader = true;
      })
      .addCase(GetSupportTicketDetails.rejected, (state) => {
        state.loading.GetSupportTicketDetailsLoader = false;
      })
      .addCase(GetSupportTicketDetails.fulfilled, (state, action: any) => {
        state.loading.GetSupportTicketDetailsLoader = false;
        state.TicketDetails = action.payload;
      })
      .addCase(GetFaqs.pending, (state) => {
        state.loading.FaqsLoader = true;
      })
      .addCase(GetFaqs.rejected, (state) => {
        state.loading.FaqsLoader = false;
      })
      .addCase(GetFaqs.fulfilled, (state, action: any) => {
        state.loading.FaqsLoader = false;
        state.Faqs = action.payload;
      })
      .addCase(GetNotifications.pending, (state) => {
        state.loading.NotificationsLoader = true;
      })
      .addCase(GetNotifications.rejected, (state) => {
        state.loading.NotificationsLoader = false;
      })
      .addCase(GetNotifications.fulfilled, (state, action: any) => {
        state.loading.NotificationsLoader = false;
        state.Notifications = action.payload.notifications;
      })
      .addCase(MarkNotificationRead.pending, (state) => {
        state.loading.NotificationsLoader = true;
      })
      .addCase(MarkNotificationRead.rejected, (state) => {
        state.loading.NotificationsLoader = false;
      })
      .addCase(MarkNotificationRead.fulfilled, (state, action: any) => {
        state.loading.NotificationsLoader = false;
      });
  },
});

export const { flushAccount, setSidebarCollapse } = accountSlice.actions;

export default accountSlice.reducer;
