import { Box, Flex, Icon, Button, Heading, Link, Image, useMediaQuery } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";

import { FeedbackForm } from "../../components/FeedbackForm";
import Header from "../../components/layout/Header";
import SidebarMenuListitems from "../../components/layout/SidebarMenuListitems";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setSidebarCollapse } from "../../redux/modules/account";
import { setCookieData } from "../../redux/modules/cookie";
import { useLocation } from "react-router-dom";
import profileName from "../../utils/profileName";
import WelcomeMessage from "../overview/WelcomeMessage";
import Notifications from "../overview/Notifications";
import capitalizeBusinessName from "../../utils/capitalizeBusinessName";
import capitalizeText from "../../utils/capitalizeText";
import {
  getProfile,
  getConfiguration
} from "../../redux/modules/account";
import { toastrMsg } from "../../utils/toastrMsg";
import { getCorridors } from "../../redux/modules/static";
import { FaChevronDown } from "react-icons/fa";
import StaticContentFrame from "../StaticContentFrame";
import Footer from "./Footer";

interface MainLayoutProps {
  children: any;
}

const MainLayout = (props: MainLayoutProps) => {
  const { children } = props;
  const firstFieldRef = useRef(null);
  const dispatch = useAppDispatch();
  const [displayFeedback, setDisplayFeedback] = useState(false);
  const { isSidebarCollapased, tenancyConfigData } = useAppSelector((state) => ({
    isSidebarCollapased: state.account.isSidebarCollapased,
    tenancyConfigData: state.affiliate.multiTenancyConfigData
  }));
  const {
    cookieData,
    user
  } = useAppSelector((state) => ({
    cookieData: state.cookie.cookieData,
    user: state.account.userData,
  }));
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const [isIpad] = useMediaQuery("(max-width: 997px)");
  const location = useLocation();
  const [loader, setLoader] = useState(true);
  const [userData, setUser] = useState<any>(user || {});
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  
  useEffect(() => {
    setUser(user); 
  }, [user]);

  useEffect(() => {
    const getConfig = async () => {
      const res: any = await dispatch(getConfiguration());
      setLoader(false);
      if (res.error) {
        toastrMsg("Error", res.error.message);
      }
    };
    const getUserprofile = async () => {
      let res: any = await dispatch(getProfile());
      if (res.error) {
        toastrMsg("Error", res.error.message);
      } else {
        setUser(res.payload.data);
      }
    };
    if(tenancyConfigData.isFrame) {
      dispatch(getCorridors());
      getConfig();
      getUserprofile();
    }
  }, [dispatch]);

  const handleCollapse = () => {
    dispatch(setSidebarCollapse(!isSidebarCollapased));
  };

  const setCookieStorage = () => {
    dispatch(setCookieData(true));
    localStorage.setItem("RH_COOKIE_DATA", "true");
  };

  useEffect(() => {
    const cookieValue = localStorage.getItem('RH_COOKIE_DATA');
    if (cookieValue) {
      dispatch(setCookieData(true));
    } else {
      dispatch(setCookieData(false));
    }
  }, [dispatch])
  
  const [toggleIsOpen, setToggleIsOpen] = useState(false);
  const toggleOpen2 = () => {
    setToggleIsOpen(!toggleIsOpen);
  };
  
  return (
    <Box w="100%" h="auto" minH="100vh" pos="relative" backgroundColor={tenancyConfigData.isFrame && '#EDEBE9'}>
      {displayFeedback ? (
        <Box
          onClick={() => {
            setDisplayFeedback(false);
          }}
          className="overlay"
        ></Box>
      ) : null}
      {!tenancyConfigData.isFrame && <Header />}
      <Flex
        justify="space-between"
        w="100%"
        flexDir={{ sm: "column", lg: "row", xl: "row" }}
        gap="10"
        p={tenancyConfigData.isFrame ? {sm: '56px 16px 0', lg: '80px 62px 0'} : { sm: "0", lg: "123px 0 0" }}
        // paddingTop={{ sm: "0", lg: tenancyConfigData.isFrame ? '0' : "73px" }}
        minH="100vh"
        h="100%"
      >
        {/* Side navbar */}
        <Box
          pl="27px"
          display={{ sm: "none", lg: "flex", xl: "flex" }}
          className={
            isSidebarCollapased ? "sidebar_width" : "collapsible_width"
          }
          h="auto"
          flex="1"
          pos="relative"
          borderRadius={tenancyConfigData.isFrame && '5'}
          backgroundColor={tenancyConfigData.isFrame && '#fff'}
          mb={cookieData ? "10.2rem" : "9.0rem"}
        >
          <Icon
            pos="absolute"
            className={
              isSidebarCollapased ? "sidebarIcon_left" : "sidebarIcon_right"
            }
            onClick={handleCollapse}
            w="25px"
            h="25px"
            right="-10px"
            top="10px"
            zIndex="999"
            cursor="pointer"
            as={MdArrowForwardIos}
          />
          <SidebarMenuListitems isSidebarCollapased={isSidebarCollapased} />
        </Box>
        {/* main content */}
        <Box
          as="main"
          pos="relative"
          zIndex="99"
          className={
            isSidebarCollapased
              ? "width_withoutCollapse custom_card"
              : "width_withCollapse custom_card"
          }
          w={{ sm: "100%" }}
          maxW={{md: isSidebarCollapased ? "94.3%" : '90%', lg: isSidebarCollapased ? "77%" : '95%', xl: isSidebarCollapased ? "80%" : '95%', xxl: isSidebarCollapased ? "83%" : '100%'}}
          mx="auto"
          justifyContent="center"
          p={{ sm: "2rem 1.6rem", lg: "3.2rem" }}
          mt='0'
          mb={cookieData ? "10.2rem" : "9.0rem"}
          borderRadius={tenancyConfigData.isFrame && '5'}
          backgroundColor={tenancyConfigData.isFrame && '#fff'}
        >
          <Box>
            <Flex flex="1" mb="2.2rem" alignItems='center' gap={{sm: "0", lg:'10'}} justifyContent={'space-between'} >
              {location.pathname == '/overview' && !tenancyConfigData.isFrame && !isMobile &&
                <WelcomeMessage
                  userName={profileName(userData.company?.businessName || userData.firstName)}
                />
              }
              <Flex align="left" flexDir='row' gap={{sm: "1", lg:'0'}} pos={'relative'}>
                <Heading
                  as="p"
                  size="md_light"
                  color="#605E5C"
                  textTransform="initial"
                >
                  Powered by mito.money
                </Heading>
                {isMobile &&
                  <FaChevronDown 
                    color='rgba(155, 155, 155, 1)' 
                    fontSize={'18px'} 
                    onClick={toggleOpen2} 
                    style={{ cursor: 'pointer' }} // Added cursor pointer for better UX
                  />
                }
                <StaticContentFrame toggleData={toggleIsOpen}/>
              </Flex>
              {isMobile &&
                <>
                  {!tenancyConfigData.isFrame &&
                    <Flex mr={tenancyConfigData.isFrame ? {sm: '1', lg: '3'} : "3"}>
                      <Notifications />
                    </Flex> 
                  }
                </>          
              }
              {tenancyConfigData.isFrame && 
                <Flex gap={tenancyConfigData.isFrame ? {sm: "5px", lg: "10px"} : "10px"} alignItems={'center'}>    
                  <Flex mr={tenancyConfigData.isFrame ? {sm: '1', lg: '3'} : "3"}>
                    <Notifications />
                  </Flex>     
                  <Flex direction="column" gap="5px" alignItems={'flex-end'}>
                    {userData.firstName && (
                      <Heading display={{sm: "none", lg:'inline-block' }} as="p" mr="8px" size="xl_light">
                        {userData.isBusinessProfie
                        ? `${profileName(userData.company?.businessName)}`
                        : `${profileName(userData.firstName)} ${profileName(
                            userData.lastName)}`
                      }
                      </Heading>
                    )}
                    <Heading display={{sm: "none", lg:'inline-block' }} as="p" mr="8px" size="md_light" color='#605E5C'>
                      {userData.isBusinessProfie ? "Business" : "Individual"} User
                    </Heading>
                  </Flex>
    
                  <Flex>
                    {userData.imageUrl ? 
                      <Image src={userData.imageUrl} alt="" width="40px" height="40px" objectFit={'cover'} borderRadius={'50%'} />
                    :
                      <>
                        {userData.firstName && (
                          <>
                            {userData.isBusinessProfie ? (
                              <Heading
                                display="inline-flex"
                                as="h5"
                                alignItems={'center'}
                                justifyContent={'center'}
                                size="lg_medium"
                                variant="white"
                                bg="blue.300"
                                py="5px"
                                px="6px"
                                mr="8px"
                                borderRadius="25px"
                                cursor="pointer"
                                minW="40px"
                                minH="40px"
                              >
                                {capitalizeBusinessName(userData.company?.businessName)}
                              </Heading>
                            ) : (
                              <Heading
                                display="inline-flex"
                                as="h5"
                                alignItems={'center'}
                                justifyContent={'center'}
                                size="lg_medium"
                                variant="white"
                                bg="blue.300"
                                py="5px"
                                px="6px"
                                mr="8px"
                                borderRadius="25px"
                                cursor="pointer"
                                minW="40px"
                                minH="40px"
                              >
                                {capitalizeText(userData.firstName)}
                                {capitalizeText(userData.lastName)}
                              </Heading>
                            )}
                          </>
                        )}
                      </>
                    }
                  </Flex>
                  <Flex>
                    {isIpad && <FaChevronDown color='rgba(155, 155, 155, 1)' fontSize={'18px'} onClick={toggleOpen}/>}
                  </Flex>
                </Flex>
              }
            </Flex>
            {isOpen &&
              <>
                <Box 
                  opacity={'0.4'}
                  position={'fixed'}
                  bottom={'0'}
                  width={'100%'}
                  zIndex={'99'}
                  left="0"
                  right="0"
                  bg="#000"
                  top="0"
                  onClick={toggleOpen}
                ></Box>
                <Box
                  position={'fixed'}
                  bottom={'0'}
                  width={'100%'}
                  zIndex={'999'}
                  left="0"
                  right="0"
                  bg="#fff"
                  p="0 24px 50px"
                  borderRadius={'12px 12px 0 0'}
                  onClick={toggleOpen}
                >
                  <SidebarMenuListitems />
                </Box>
              </>
            }
            {children}
            <Footer />
          </Box>
        </Box>
        <>
          {cookieData == false && (
            <Flex
              zIndex="99999"
              display={cookieData == false || cookieData != null ? 'block' : 'none'}
            >
              <Box
                pos="fixed"
                zIndex="99999"
                right="0"
                left="0"
                bottom="0"
                bg="var(--clr1)"
                boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                p={{ sm: "15px 20px 10px", lg: "15px 70px 10px" }}
                display={{ lg: "inline-flex" }}
              >
                <Box
                  width={{ sm: "100%", lg: "90%" }}
                >
                  <Heading
                    as="h4"
                    fontSize={{ sm: "10px", lg: "16px" }}
                    variant="white"
                    letterSpacing="0.2px"
                    lineHeight="1.5"
                    fontWeight="normal"
                    textTransform="inherit"
                  >
                    Thank you for visiting Mito.Money By using the site, you agree to our <Link href="/privacy" color="#ffb1b1">privacy policy</Link> and our use of cookies. We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.
                  </Heading>
                </Box>
                <Box
                  width={{ sm: "100%", lg: "10%" }}
                >
                  <Button
                    variant="Solid_red"
                    onClick={setCookieStorage}
                    p={{ sm: "5px 15px", lg: "15px 20px" }}
                    transition="0.2s ease"
                    borderRadius={{ sm: "10px", lg: "10px" }}
                    h="70% !important"
                    w="50%"
                    ml="10%"
                  >
                    <Heading
                      as="h4"
                      size="lg_small"
                      variant="red"
                      letterSpacing="0.2px"
                    >
                      Got it
                    </Heading>
                  </Button>
                </Box>
              </Box>
            </Flex>
          )}
        </>
        {/* )} */}
      </Flex>
    </Box>
  );
};

export default MainLayout;
