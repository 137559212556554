import { extendTheme, theme as base, type ThemeConfig } from "@chakra-ui/react";
import { StepsTheme as Steps } from "../components/chakra-ui-steps";

import Button from "./components/button";
import Input from "./components/input";
import List from "./components/list";
import Select from "./components/selectbox";
import Menu from "./components/menu";
import Table from "./components/table";
import Checkbox from "./components/checkbox";
import Tabs from "./components/tabs";
import Heading from "./components/typography";
import Radio from "./components/radio";
import Accordion from "./components/accordian";
import Switch from "./components/switch";

const config: ThemeConfig = {
	initialColorMode: "light",
	useSystemColorMode: false,
};

const theme = {
	components: {
		Button,
		Input,
		List,
		Select,
		Menu,
		Table,
		Checkbox,
		Tabs,
		Heading,
		Radio,
		Accordion,
		Steps,
		Switch,
	},
	styles: {
		global: {
			"html, body": {
				fontFamily: "SFProDisplay",
				height: "100%",
				fontSize: "62.5%",
			},
			".chakra-input__group": {
        isolation: "auto !important",
      },
		},
	},
	fonts: {
		heading: `SFProDisplay , ${base.fonts?.heading}`,
	},
	breakpoints: {
		sm: "320px",
		md: "768px",
		lg: "991px",
		xl: "1367px",
		xxl: "1549px",
		xxxl: "1920px",
	},
	fontSizes: {
		xs: "10px",
		sm: "12px",
		md: "14px",
		lg: "16px",
		xl: "18px",
		xxl: "20px",
		xxxl: "24px",
		Enlarge: "32px",
		LaptopFontMd: "40px",
		LaptopFont: "60px",
		Banner: "70px",
	},
	fontWeights: {
		hairline: 100,
		thin: 200,
		light: 300,
		normal: 400,
		medium: 500,
		semibold: 600,
		bold: 700,
		extrabold: 800,
		black: 900,
	},
	colors: {
		transparent: "transparent",
		black: {
			100: "#605E5C",
			150: "#201F1E",
			200: "#323130",
			900: "#000000",
		},
		white: {
			900: "#ffffff",
		},
		blue: {
			50: "#ECF5FF",
			// 60: "#F9FCFF",
			60: 'var(--navBarClr)',
			70: "#F4F6F8",
			// 100: "#99A0FB",
			// 150: "#D3E9FF",
			100: 'var(--navBarClr)',
			150: 'var(--navBarClr)',
			// 200: "#6DB0F3",
			200: 'var(--navBarClr)',
			// 250: "#9FCFFF",
			250: 'var(--navBarClr)',
			// 300: "#4296EB",
			300: 'var(--navBarClr)',
			// 400: "#2E5BFF",
			400: 'var(--clr1)',
			500: "#D3E9FF"
		},
		grey: {
			10: "#FAF9F8",
			50: "#CFD8DC",
			100: "#A19F9D",
			150: "#D2D0CE",
			200: "#F3F2F1",
			250: "#EDEBE9",
			300: "#C8C6C4",
			400: "#9B9B9B",
			600: "#E1DFDD",
			700: "#7B7B7B",
		},
		purple: {
			300: "#98AEFF",
			400: 'var(--clr1)',
			500: "#998DC9",
		},
		green: {
			300: 'var(--navBarClr)',
			500: "#1FB4AB",
			600: "#4caf50",
			700: "#19B019",
		},
		orange: {
			300: "#FFD1B2",
			500: "#FC881D",
			700: "#FF7013"
		},
		red: {
			600: "#A80000",
			700: "#A00F0F",
		},
		dashboard: {
			purple_light: "hsl(259deg 100% 67% / 2%)",
		},
	},
	boxShadow: {
		light: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
	},
	config,
};

const main = extendTheme(theme);

export default main;
