const getUrlQueryString = (data: any) => {
  let query: any = [];
  if (data.pageNumber && data.rowsOfPage) {
    query.push(`PageNo=${data.pageNumber}&PageSize=${data.rowsOfPage}`);
  }
  if (data.text) {
    query.push(`SearchingText=${data.text}`);
  }
  query.push(`OrderByDesc=${!!data.order}`);
  if (data.column) {
    query.push(`OrderByColumn=${data.column}`);
  }
  if (query.length > 0) {
    return `?${query.join("&")}`;
  }
  return "";
};

export default getUrlQueryString;
