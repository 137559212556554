type EventCallback = (data: unknown) => void;
type EventName = string;
type EventId = string;

interface Callbacks {
    [eventName: string]: {
        [eventId: string]: EventCallback;
    };
}

const Callbacks: Callbacks = {};

export const Events = {
    on: (eventName: EventName, eventId: EventId, callback: EventCallback): EventId => Events.listen(eventName, eventId, callback),
    listen: (eventName: EventName, eventId: EventId, callback: EventCallback): EventId => {
        if (!eventName) {
            throw new Error("Event name cannot be empty");
        }

        Callbacks[eventName] = Callbacks[eventName] || {};
        Callbacks[eventName][eventId] = callback;

        return eventId;
    },
    trigger: (eventName: EventName, data: unknown): void => {
        const eventCallbacks = Callbacks[eventName];

        for (const callback of Object.values(eventCallbacks || {})) {
            callback(data);
        }
    },
    remove: (eventName: EventName, eventId: EventId): void => {
        if (Callbacks[eventName]) {
            delete Callbacks[eventName][eventId];
        }
    },
    removeAll: (eventName: EventName): void => {
        delete Callbacks[eventName];
    },
};