import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import api from "../../utils/apiClient";

export interface AffiliateState {
  multiTenancyConfigData: any;
  siteLogo: any;
  AffiliateLoading: any;
}

const initialState: AffiliateState = {
  siteLogo: "",
  AffiliateLoading: false,
  multiTenancyConfigData: {
    primary: '#008080',
    border: '#ddd'
  },
};

export const getAffiliateConfiguration = createAsyncThunk(
	"Affiliate/GetAffiliateConfiguration",
	async (data: any) => {
		const response = await api.get("Affiliate/GetAffiliateConfiguration", {
			data,
		});
		return response;
	}
);

export const AffiliateSlice = createSlice({
  name: "Affiliate",
  initialState,
  reducers: {
    setMultiTenancyConfigData: (state, action: PayloadAction<any>) => {
      state.multiTenancyConfigData = action.payload;
    },
    setAffiliateLoading: (state, action: PayloadAction<any>) => {
      state.AffiliateLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAffiliateConfiguration.pending, (state) => {
        state.AffiliateLoading = true;
      })
      .addCase(getAffiliateConfiguration.rejected, (state) => {
        state.AffiliateLoading = false;
      })
      .addCase(getAffiliateConfiguration.fulfilled, (state, action) => {
        state.multiTenancyConfigData = action.payload;
        state.siteLogo = action.payload.logo;
        state.AffiliateLoading = false;
      })
   }
});

export const { setMultiTenancyConfigData, setAffiliateLoading } = AffiliateSlice.actions;

export default AffiliateSlice.reducer;
