import { Box, SkeletonText } from "@chakra-ui/react";
import React from "react";

const SkeletonOne = () => {
  return (
    // <Flex justifyContent="bottom" pt="5px" pl="4px">
    <Box padding="6" boxShadow="lg" bg="white">
      <SkeletonText mt="4" noOfLines={1} spacing="4" />
    </Box>
    // </Flex>
  );
};

export default SkeletonOne;
