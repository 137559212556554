import { ComponentMultiStyleConfig } from "@chakra-ui/react";

const Table: ComponentMultiStyleConfig = {
	parts: ["table", "tr", "th", "td"],
	variants: {
		SimpleTable: {
			th: {
				fontWeight: "medium",
				fontSize: "lg",
				color: "#605E5C",
				borderBottom: "1px",
				borderColor: "#C8C6C4",
				pb: "9px",
				textTransform: "capitalize",
				cursor: "default",
			},
			td: {
				fontWeight: "light",
				fontSize: "md",
				color: "#605E5C",
				py: "10px ",
			},
		},
		DashBoard: {
			tr: {
				borderBottom: "1px solid",
				borderColor: "grey.600",
				py: "11px",
				px: "14px",
				_last: {
					borderBottom: "0",
				},
			},

			th: {
				fontWeight: "medium",
				fontSize: { sm: "md", xl: "lg" },
				lineHeight: { sm: "16px", xl: "20px" },
				color: "black.100",
				textTransform: "capitalize",
				cursor: "default",
			},

			td: {
				fontWeight: "light",
				fontSize: { sm: "md", xl: "lg" },
				lineHeight: { sm: "16px", xl: "20px" },
				color: "black.100",
			},
		},
	},
};

export default Table;
