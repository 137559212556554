import { Children } from "react";

import AppLink from "../../components/AppLink";

const RouteLink = (props: any) => {
  const { className, to, color, onClick, text, fontsize, ...args } = props;
  return (
    <AppLink
      className={className}
      fontSize={fontsize}
      href={to}
      color={color}
      onClick={onClick}
      {...args}
    >
      {text || Children}
    </AppLink>
  );
};

export default RouteLink;
