import { useToast } from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import Toast from "./Toast";

export function useToastHook(
  type: "success" | "error" | "alert" | "info",
  duration: number
) {
  const [state, setState] = useState<
    { message: string; status: string } | undefined
  >();
  const toast = useToast();
  const toastRefs = useRef<(() => void)[]>([]);

  useEffect(() => {
    if (state) {
      const { message, status } = state;

      toast({
        title: status,
        description: message,
        isClosable: true,
        duration: duration,
        position: "top",
        render: ({ onClose }) => {
          toastRefs.current.push(onClose);
          return <Toast type={type} message={message} onClose={onClose} />;
        },
      });
    }
  }, [duration, state, toast, type]);

  return [state, setState] as const;
}
export const useToastSuccessHook = () => useToastHook("success", 2000);
export const useToastErrorHook = () => useToastHook("error", 3000);
export const useToastAlertHook = () => useToastHook("alert", 3000);
export const useToastInfoHook = () => useToastHook("info", 3000);
