import { ComponentMultiStyleConfig } from "@chakra-ui/react";

const Accordion: ComponentMultiStyleConfig = {
	parts: ["root", "container", "button", "panel", "icon"],

	variants: {
		updateField: {
			root: {
				border: "0px",
			},

			container: {
				borderTopWidth: "0px",
				_last: {
					borderBottomWidth: "0px",
				},
			},
			button: {
				p: "0px",
				justifyContent: "flex-start",
				_hover: {
					bg: "transparent",
				},
			},
			icon: {
				ml: "15px",
				fontSize: "16px",
				color: "blue.400",
			},
			panel: {
				mt: "20px",
				p: "0px",
			},
		},
		transactionDetail: {
			root: {
				border: "0px",
			},

			container: {
				borderTopWidth: "0px",
				_last: {
					borderBottomWidth: "0px",
				},
			},
		},
	},
};

export default Accordion;
