export function isOnline(): boolean {
    return navigator.onLine;
}
export function handleNetworkError(err: any): string {
    if (!isOnline()) {
        return "It seems you are offline. Please check your internet connection.";
    }
// Handle server errors with status codes (502, 503, 504)
    if (err?.response?.status === 502) {
        return "The server is currently down. Please try again later.";
    }
    if (err?.response?.status === 503) {
        return "The server is temporarily unavailable. Please try again later.";
    }
    if (err?.response?.status === 504) {
        return "The request timed out. The server may be down. Please try again later.";
    }
    if (err?.code === "ECONNABORTED") {
        return "The request timed out. Please check your network connection.";
    }
    if (err?.code === "ERR_NAME_NOT_RESOLVED") {
        return "The request timed out. The server may be down. Please try again later.";
    }
    if (err?.message?.includes("Request has been terminated")) {
        return "The request timed out. The API server may be unresponsive. Please try again later.";
    }
    return err
}