import { useEffect, useState, } from "react";
import { useAppSelector } from "../../redux/hooks";
import { Helmet } from 'react-helmet';

const HeaderConfig: React.FC = () => {
  const {tenancyConfigData} = useAppSelector((state) => ({
    tenancyConfigData: state.affiliate.multiTenancyConfigData,
  }));
  const [logoImage, setLogoImage] = useState<any>("");

  useEffect(() => {
    if (tenancyConfigData?.affiliateFavicon) {
      // Update favicon
      setLogoImage(tenancyConfigData.affiliateFavicon);
    } else {
      // Fallback to default favicon
      setLogoImage("/favicon.ico");
    }
  }, [tenancyConfigData?.affiliateFavicon]);
  
  return(
    <div>
      <Helmet>
        <title>{tenancyConfigData?.affiliateName}</title>
        <link rel="icon" href={logoImage} />
        <link rel="apple-touch-icon" href={logoImage} />
      </Helmet>
    </div>
  )
};

export default HeaderConfig;