import { Box, Heading, UnorderedList, ListItem } from "@chakra-ui/react";

import AccordionHome from "../../components/home/Accordion";
import { useAppSelector } from "../../redux/hooks";

const FAQContent = () => {
  const {tenancyConfigData} = useAppSelector((state) => ({
    tenancyConfigData: state.affiliate.multiTenancyConfigData,
  }));

  return (
    <Box as="section" w="100%" maxW="90rem">
      <Box id="section1">
        <Heading as="h4" size="xxxl_medium" variant="black" mb="3.2rem">
          Send Money
        </Heading>
        <Box>
          <AccordionHome
            my="0"
            title="Can I pay by Cash or Card?"
            content="Due to Money Laundering regulations, we do not accept cash,you can make payments via your credit (All major cards accepted) or debit card but this will only be made available after you have made a bank transfer transaction?."
          />

          <AccordionHome
            my="1.5rem"
            title="Can I get a discount?"
            content={`${tenancyConfigData?.affiliateName} offers various forms of rewards to its loyal customers from time to time, which could be in the form of a promo code or a discount voucher which can be used directly when making a transaction?. We currently do not offer referral bonuses or discounts.`}
          />

          <AccordionHome
            my="0"
            title="What is the maximum or minimum amount I can transfer?"
            content="There is no limit to how much can be transferred, once all necessary documentations to justify the transaction has been provided. Kindly note that sending limits when paying by card is subject to the card issuer."
          />

          <AccordionHome
            my="1.5rem"
            title="What is the cut off time for payment?"
            content="We are at your service round the clock, ensuring we fulfil all your transactions, so there is no payment cut off time, whether midday or midnight."
          />

          <AccordionHome
            my="0"
            title="What happens after confirmation?"
            content="Once you complete a transaction and it is confirmed you get a notification immediately to this effect, the payment order is sent to our processing portal, where we will confirm receipt of your funds and your beneficiary will be credited with the amount instructed in minutes."
          />

          <AccordionHome
            my="1.5rem"
            title="What should I do when my transfer is cancelled"
            content={
              <>
                <Heading as="h4" size="lg_medium" textTransform="initial">
                  There are a few reasons why your transfer might have been
                  cancelled.
                </Heading>
                <UnorderedList my="3rem">
                  <ListItem>
                    We did not receive any payment for the transfer
                  </ListItem>
                  <ListItem my="1.2rem">
                    We did not receive the required documents
                  </ListItem>
                  <ListItem>Incorrect recipient details</ListItem>
                  <ListItem my="1.2rem">
                    Your recipient did not pick up the transfer (in the case of
                    Cash pick-up)
                  </ListItem>
                  <ListItem>
                    Operational reasons outside of our control
                  </ListItem>
                </UnorderedList>
                <Heading as="p" size="lg_medium" textTransform="initial">
                  Sometimes people might try and make multiple transfers but end
                  up paying for only one of them. Please always check your
                  transfer history to see if the cancelled transfer might be a
                  duplicate transfer.
                </Heading>
                <Heading
                  as="p"
                  size="lg_medium"
                  textTransform="initial"
                  mt="2rem"
                >
                  If none of these reasons apply to your cancelled transfer,
                  please contact our Customer Support team who will check for
                  you.
                </Heading>
              </>
            }
          />
          <AccordionHome
            my="0"
            title="Cancellation and Refunds"
            content={
              <>
                <Heading as="p" size="lg_medium" textTransform="initial">
                  If you have any problems using the Service you should contact
                  us through the channels listed at the end of this FAQ.
                </Heading>
                <Heading
                  as="p"
                  size="lg_medium"
                  textTransform="initial"
                  mt="2rem"
                >
                  You have the statutory right to cancel your agreement with us
                  after you have submitted a transaction?. This right of
                  cancellation continues until fourteen days after you have
                  submitted the transaction, or until we have completed the
                  contract by paying the Pay-out Amount to the Recipient,
                  whichever is the earlier. If you exercise your right to cancel
                  under this clause, we may make a cancellation charge.
                </Heading>
                <Heading as="p" size="lg_medium" textTransform="initial">
                  If you wish to exercise your right to cancel under this
                  clause, you must submit a written request to one of the
                  contact points listed at the bottom of this Terms and
                  Conditions, giving the Sender&apos;s full name, address, and
                  phone number, together with the Transaction tracking/reference
                  number, Transaction Amount, and the reason for your refund
                  request.
                </Heading>
                <Heading
                  as="p"
                  size="lg_medium"
                  textTransform="initial"
                  my="2rem"
                >
                  Any refunds will be credited back to the same Payment
                  Instrument used to fund the Transaction and in the same
                  currency. No adjustment will be made for any currency
                  fluctuations, which may have occurred in the meanwhile.
                </Heading>
                <Heading as="p" size="lg_medium" textTransform="initial">
                  Refunds once approved are initiated on the same business day
                  but could take 2-5 working days to reflect in your account.
                </Heading>
              </>
            }
          />
        </Box>
      </Box>
      <Box id="section2" mt="5.6rem">
        <Heading as="h4" size="xxxl_medium" variant="black" mb="3.2rem">
          Receiving Money
        </Heading>
        <Box>
          <AccordionHome
            my="0"
            title="What beneficiary details do you need?"
            content="For prompt service delivery, we will need a correct bank account number, account name and beneficiary bank."
          />
          <AccordionHome
            my="1.5rem"
            title="What does my beneficiary need to access funds?"
            content={
              <>
                <Heading as="p" size="lg_medium" textTransform="initial">
                  The recipient of the funds only needs to be sure his account
                  his active and not limited or restricted in any form,
                  thereafter the beneficiary can go to the nearest ATM or his
                  bank to access funds.
                </Heading>
                <Heading
                  as="p"
                  size="lg_medium"
                  textTransform="initial"
                  mt="2rem"
                >
                  In the case of Cash pickups, an ID is required along with the
                  pickup code we will send to you.
                </Heading>
              </>
            }
          />
          <AccordionHome
            my="0"
            title="How soon will my beneficiary get the funds?"
            content="In the absence of issues or further KYC required, funds are credited to the recipient account in real time or in a few minutes."
          />
        </Box>
      </Box>
      <Box id="section3" mt="5.6rem">
        <Heading as="h4" size="xxxl_medium" variant="black" mb="3.2rem">
          Security
        </Heading>
        <Box>
          <AccordionHome
            my="0"
            title="How safe are my details?"
            content="We are regulated by FCA and bound by the data protection regulation as well. We employ certified state of the art technology to protect our site and most especially your details."
          />
          <AccordionHome
            my="1.5rem"
            title="What do you do with my details?"
            content="Your details are stored securely on our servers, which makes it easier for you to make transfers when you return to our site at another date."
          />
          <AccordionHome
            my="0"
            title="Can I make a transfer on behalf of a third party
                         or allow someone else access to my account?"
            content="We will strictly advise you not to transfer funds for a third party or give someone else your access to make transfers on your behalf. This is to ensure the safety of your details and funds."
          />
        </Box>
      </Box>
      <Box id="section4" mt="5.6rem">
        <Heading as="h4" size="xxxl_medium" variant="black" mb="3.2rem">
          My Account
        </Heading>
        <Box>
          <AccordionHome
            my="0"
            title="Can I edit my personal information after I sign up"
            content={
              <>
                <Heading as="p" size="lg_medium" textTransform="initial">
                  You can edit your personal information such as your password,
                  at any time.
                </Heading>
                <Heading
                  as="p"
                  size="lg_medium"
                  textTransform="initial"
                  mt="2rem"
                >
                  The security of your account is very important to us, so we
                  ask that if you need to make any changes to your name,
                  birthdate or home country that you contact us directly.
                </Heading>
                <Heading
                  as="p"
                  size="lg_medium"
                  textTransform="initial"
                  mt="2rem"
                >
                  This is why it is important to ensure the details inputted
                  during registration are correct and match what is on your
                  government issued ID.
                </Heading>
              </>
            }
          />
        </Box>
      </Box>
      <Box id="section5" mt="5.6rem">
        <Heading as="h4" size="xxxl_medium" variant="black" mb="3.2rem">
          General Information
        </Heading>
        <Box>
          <AccordionHome
            my="0"
            title={`How do I close my ${tenancyConfigData?.affiliateName} Account`}
            content={
              <>
                <Heading as="p" size="lg_medium" textTransform="initial">
                  For regulatory reasons, we can only permanently delete
                  accounts which haven’t been used in 5 years. However, we can
                  always deactivate an account for you.
                </Heading>
                <Heading
                  as="p"
                  size="lg_medium"
                  textTransform="initial"
                  mt="2rem"
                >
                  If you wish to do so, please contact our Customer Support
                  team.
                </Heading>
              </>
            }
          />
          <AccordionHome
            my="1.5rem"
            title="Why was my account blocked"
            content={
              <>
                <Heading as="p" size="lg_medium" textTransform="initial">
                  If you have signed up for more than one account, one of your
                  accounts may have been deactivated, as each {tenancyConfigData?.affiliateName} user can
                  only have one account type.
                </Heading>
                <Heading
                  as="p"
                  size="lg_medium"
                  textTransform="initial"
                  mt="2rem"
                >
                  To find out more information about why your account may have
                  been deactivated, please contact our Customer Support team.
                </Heading>
              </>
            }
          />
        </Box>
      </Box>
      <Box id="section6" className="faq-contact-section" mt="5.6rem">
        <Heading
          as="h4"
          size="xxxl_medium"
          variant="black"
          mb="3.2rem"
          textTransform="initial"
        >
          In the event of queries or further enquiries
        </Heading>
        <Box mt="2.4rem">
          <Heading
            as="p"
            size="lg_medium"
            variant="primary"
            textTransform="initial"
          >
            Kindly send an email with all your enquiries to:
          </Heading>
          <Heading
            as="h4"
            size="lg_medium"
            variant="primary"
            textTransform="initial"
            mt="0.2rem"
            color='var(--clr1)'
          >
            <a href={`mailto: ${tenancyConfigData?.contactEmailAddress}`}>{tenancyConfigData?.contactEmailAddress}</a>
          </Heading>
          <Heading
            as="h4"
            size="lg_medium"
            variant="primary"
            textTransform="initial"
            my="1.5rem"
          >
            or call us on <a href="tel:02087314574" style={{color:'var(--clr1)'}}>02087314574</a>
          </Heading>
          <Heading
            as="h4"
            size="lg_medium"
            variant="primary"
            textTransform="initial"
          >
            You can also:
          </Heading>
          <Heading
            as="h4"
            size="lg_medium"
            variant="primary"
            textTransform="initial"
            mt="0.2rem"
          >
            Call/Message on WhatsApp:
          </Heading>
          {tenancyConfigData?.mobile && (
          <Heading
            as="h4"
            size="lg_medium"
            variant="primary"
            textTransform="initial"
            mt="0.2rem"
          >
            <a href={`https://wa.me/${tenancyConfigData?.mobile}`} target="_blank" style={{color:'var(--clr1)'}}>
              {tenancyConfigData?.mobile}
            </a>
          </Heading>
          )}
          {tenancyConfigData?.telephone && (
          <Heading
            as="h4"
            size="lg_medium"
            variant="primary"
            textTransform="initial"
            mt="0.2rem"
          >
            <a href={`https://wa.me/${tenancyConfigData?.telephone}`} target="_blank" style={{color:'var(--clr1)'}}>
              {tenancyConfigData?.telephone}
            </a>
          </Heading>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default FAQContent;
