import React, {Suspense} from "react";
import ReactDOM from "react-dom/client"; // Updated import for React 18
import App from "./components/app/app";
import { ChakraProvider } from "@chakra-ui/react";
import main from "./theme/theme";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import ErrorBoundary from "./components/layout/ErrorBoundary";
import FacebookPixel from './components/fb-scripts';
import clientConfig from "./clientConfig";
import StyleConfiguration from "./components/styleConfiguration";
import HeaderConfig from "./components/layout/HeaderConfig";
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import Loading from "./components/loading";
import HttpsRedirect from 'react-https-redirect';

const widgetContainerId = "widget-container";

export default {
  init: (widgetConfig: { frameKey: string, target: string }) => {
    return {
      render: (args?: { frameKey: string, target: string }) => {
        if(widgetConfig.target){
          const s = document.createElement("div");
          s.id = widgetContainerId;
          s.innerHTML = widgetConfig.target
        }else {
          const s = document.createElement("div");
          s.id = widgetContainerId;
          document.body.appendChild(s);
        }

        const root = ReactDOM.createRoot(
          document.getElementById(widgetConfig.target || widgetContainerId) as HTMLElement
        ); // Create the root using React 18 API

        root.render(
          <>
            <Helmet>
              <meta
                name="apple-pay"
                content={`merchant-id=${clientConfig.appleMerchantId}, environment=${
                  process.env.REACT_APP_ENV === "pord"
                    ? "production"
                    : "development"
                }`}
              />
              <script src="https://cdn.checkout.com/js/framesv2.min.js" />
              <script src="https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js" />
              <FacebookPixel />
            </Helmet>
            <Provider store={store}>
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <BrowserRouter future={{ v7_relativeSplatPath: true, v7_startTransition: true }}>
                    <ChakraProvider theme={main}>
                      <style>
                        <StyleConfiguration widgetConfig={widgetConfig} />
                      </style>
                      <HeaderConfig />
                      <HttpsRedirect>
                        <App />
                      </HttpsRedirect>
                    </ChakraProvider>
                  </BrowserRouter>
                </Suspense>
              </ErrorBoundary>
            </Provider>
          </>,
        );
      },
      unmount: () => {
        const root = ReactDOM.createRoot(
          document.getElementById(widgetContainerId) as HTMLElement
        ); // Ensure we can unmount using the root

        root.unmount(); // Unmount the app
      },
    };
  },
};
