import {
  Box,
  Heading,
  Flex,
  ListItem,
  UnorderedList,
  Link,
} from "@chakra-ui/react";

import goToSection from "../../utils/goToSection";
import { useAppSelector } from "../../redux/hooks";

const TermsConent = () => {
  const { tenancyConfigData } = useAppSelector(
    (state) => ({
      tenancyConfigData: state.affiliate.multiTenancyConfigData
    })
  );

  const links = [
    {
      id: "terms-contract-formation-overview",
      label: "Contract Formation & Overview",
    },
    {
      id: "terms-definitions",
      label: "Definitions",
    },
    {
      id: "terms-our-obligations",
      label: "Our Obligations",
    },
    {
      id: "terms-your-obligations",
      label: "Your Obligations",
    },
    {
      id: "terms-cancellation-refunds",
      label: "Cancellation & Refunds",
    },
    {
      id: "terms-collection-of-information",
      label: "Collection of Information",
    },
    {
      id: "terms-intellectual-property-rights",
      label: "Intellectual Property Rights",
    },
    {
      id: "terms-warranties-liability",
      label: "Warranties & Liability",
    },
    {
      id: "terms-electronic-communications",
      label: "Electronic Communications",
    },
    {
      id: "terms-termination",
      label: "Termination",
    },
    {
      id: "terms-complaints",
      label: "Complaints",
    },
    {
      id: "terms-general",
      label: "General",
    },
    {
      id: "terms-security",
      label: "Security",
    },
    {
      id: "terms-contact-information",
      label: "Contact Information",
    },
  ];

  return (
    <Flex
      background='#fff'
      p={{ sm: "20px", lg: "0" }}
    >
      <Box w={{ sm: "100%", lg: "100%" }}>
        <Box>
          <Box>
            <Heading
              as="p"
              size="xxl_normal"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              id="terms-contract-formation-overview"
            >
              Contract Formation & Overview
            </Heading>

            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>1.1</b> These Terms and Conditions govern the terms under which
              you may access and use this website and the services associated
              with it (together, the "Service"). By accessing, registering with
              and using the Service, you agree to be bound by the terms of the
              Terms and Conditions. If you do not wish to be bound by the Terms
              and Conditions do not access, register with or use the Service.
              The language of these Terms and Conditions is English and all
              Services, instructions and transactions carried out in connection
              with it shall be in English.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>1.2</b> In these Terms and Conditions, the terms Mito.Money,
              "we", "us", and "our" refer to Mito.Money, together with its
              employees, directors, affiliates, successors, and assigns.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              Mito.Money™ is a trading name for Funtech Global Communications Ltd
              UK company registration number 07979772in England and Wales, with
              its registered office at Devonshire House, Manor way, Borehamwood,
              Herts, WD6 1QQ. Funtech is Authorised and Regulated by the
              Financial Services Authority (FCA) under the Payment Service
              Regulations 2009 for the provision of payment services.
              Registration details: FRN: 815146 MLR NO: 12803115
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>1.3</b> The terms "you" and "your" refer to users of the
              Service, whether in their capacity as Senders, Recipients, or
              visitors to this website.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>1.4</b> These Terms and Conditions are effective from 11
              February 2016. The Terms and Conditions may change from time to
              time, but changes will only be effective from the date they are
              made and will not change the terms on which you previously used
              the Service.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>1.5</b> The Service was created to assist customers to send
              money to their family and friends, and to receive money from
              family and friends, around the world. For security reasons, we
              recommend that you only send money through the Service to people
              you know personally. You must not use the Service to send money to
              strangers for example sellers of goods and/or services, private or
              retail.
            </Heading>
          </Box>

          {/* 2 */}
          <Box mt="50px">
            <Heading
              as="p"
              size="xxl_normal"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              id="terms-definitions"
            >
              Definitions
            </Heading>

            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              In this Terms and Conditions:
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>2.1</b> "Destination Country" means the country in which the
              Recipient receives money through the Service.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>2.2</b> "Local Taxes" means any taxes or charges payable in the
              Destination Country.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>2.3</b> "Payment Instrument" means a valid instrument of
              payment such as a bank account, debit card or credit card.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>2.4</b> "Payout Amount" means the amount paid out to the
              Recipient, after any foreign exchange conversion and excluding
              Local Taxes.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>2.5</b> "Recipient" means someone who receives money through
              the Service.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>2.6</b> "Sender" means someone who uses the Service to send
              money.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>2.7</b> "Service Fee" means the fee plus any additional charges
              applicable to each Transaction.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>2.8</b> "Service Provider" means a local bank, money exchange
              house, or other third party service providers in the Destination
              Country with whom Mito.Money works in providing the Service.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>2.9</b> "Transaction" means a specific instruction to send
              money through the Service.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>2.10</b> "Transaction Amount" means the amount of money that
              the Sender wishes to send to the Recipient, excluding any
              applicable fees and prior to any foreign exchange conversion.
            </Heading>
          </Box>

          {/* 3 */}
          <Box mt="50px">
            <Heading
              as="p"
              size="xxl_normal"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              id="terms-our-obligations"
            >
              Our Obligations
            </Heading>

            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>3.1</b> Subject to these Terms and Conditions, we agree to
              provide the Service to you using reasonable care. The Service may
              not be available in whole or in part in certain regions,
              countries, or jurisdictions.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>3.2</b> We are not obliged to process any particular
              Transaction. When you submit a Transaction, you are requesting
              that we process the Transaction on your behalf. We may, in our
              sole discretion, choose whether or not to accept the offer to
              process that Transaction. However, if we decide not to process the
              Transaction, we will notify you promptly of that decision and
              repay the money paid to us.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>3.3</b> Mito.Money reserves the right to modify or discontinue the
              service or any part of the Service without notice, at any time and
              from time to time. We may, in our absolute discretion, refuse any
              Transaction or limit the amount to be transferred, either on a per
              transaction basis or on an aggregate basis, and either on
              individual accounts or on related accounts.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>3.4</b> We may, in our sole discretion, refuse Transactions
              from certain Senders or to certain Recipients, including but not
              limited to entities and individuals on restricted or prohibited
              lists issued from time to time by the UK Government. In addition,
              not all Payment Instruments are available to all customers at all
              times and we may, in our sole discretion, refuse Transactions
              funded from certain Payment Instruments.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>3.5</b> We will attempt to process Transactions promptly, but
              any Transaction may be delayed or cancelled for a number of
              reasons including but not limited to: our efforts to verify your
              identity; to validate your Transaction instructions; to contact
              you; or otherwise to comply with applicable law; or due to
              variations in business hours and currency availability.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>3.6</b> We will attempt to provide Senders and Recipients with
              up to date information regarding the location and opening hours of
              our Service Providers by means of information on our website.
              However, you agree that Mito.Money shall not be held responsible for
              any inaccuracies that may appear in that information or any
              consequential loss, which may result from incorrect or incomplete
              information.
            </Heading>
          </Box>
          {/* 4 */}
          <Box mt="50px">
            <Heading
              as="p"
              size="xxl_normal"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              id="terms-your-obligations"
            >
              Your Obligations
            </Heading>

            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              You agree that:
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>4.1</b> you will not access, use or attempt to use the Service
              as a Sender unless you are at least 18 years old, and that you
              have the legal capacity to form a binding legal contract in the
              relevant jurisdiction;
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>4.2</b> for each Transaction that you submit, you will pay us
              the Service Fee in addition to the Transaction Amount. Payment
              becomes due at the time that you submit your Transaction. If you
              submit a Transaction that results in Mito.Money becoming liable for
              charges including but not limited to chargeback or other fees, you
              agree to reimburse us for all such fees;
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>4.2.1</b> we reserve the right to apply a convenience fee for
              processing credit cards in certain jurisdictions at our
              discretion;
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>4.3</b> in connection with your registration and use of the
              Service, you will:
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>4.3.1</b> provide us with true, accurate, current and complete
              evidence of your identity, and promptly update your personal
              information if and when it changes;
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>4.3.2</b> provide our merchant with details of one or more
              Payment Instruments; and
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>4.3.3</b> provide us with true, accurate, current and complete
              information for all Transactions. We do not accept any liability
              for damages resulting from non-payment or delay in payment of a
              money transfer to a Recipient or failure to perform a transaction
              under the Service by reason of any of these matters.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>4.4</b> when you pay for a Transaction in one currency and the
              Recipient is paid in another currency, there will be a difference
              between the exchange rate at which we buy foreign currency and the
              exchange rate provided to you. Mito.Money and its Service Providers
              usually make a small profit in these circumstances. If such
              account is denominated in another currency the amount to be
              received by the Recipient will be reduced by the amount of extra
              charges incurred by reason of the incorrect information given by
              you and we will have no obligation to make good such reduction;
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>4.5</b> when you are sending money under these Terms and
              Conditions, it is your responsibility to make sure all the
              Transaction details are accurate before submission. Once a
              Transaction has been submitted for processing it is not normally
              possible to change any of its details. You will be given the
              opportunity to confirm Transactions before submission and you must
              check the details carefully.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>4.6</b> Mito.Money will have no responsibility for any fees or
              charges you may incur by the use of a particular Payment
              Instrument to fund a Transaction. These may include but are not
              limited to unauthorised overdraft fees imposed by banks if there
              are insufficient funds in your bank account or "cash advance" fees
              and additional interest which may be imposed by credit card
              providers if they treat use of the Service as a cash transaction
              rather than a purchase transaction;
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>4.7</b> you will only use the Service to send money to people
              that you know personally and not to pay for goods or services. If,
              in breach of this clause, you choose to pay third parties for
              goods and services using the Service, you acknowledge that Mito.Money
              has no control over, and is not responsible for, the quality,
              safety, legality, or delivery of such goods or services and that
              any such use of the Service is entirely at your own risk. If
              Mito.Money reasonably believes you are using the Service to purchase
              goods or services, we reserve the right to cancel your
              Transaction(s);
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>4.8</b> both you and the Recipients will only act on your own
              behalf. You may not submit or receive a Transaction on behalf of a
              third person. If you intend to submit or receive a Transaction on
              behalf of a company, business or any entity other than a human
              individual, you must first inform Mito.Money of your desire to do so
              and provide us with any additional information about the entity we
              may request in order that we may decide whether to permit the
              Transaction;
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>4.10</b> when using our website or the Service or when
              interacting with Mito.Money, with another user or with a third party,
              you will not:
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>4.10.1</b> breach this Terms and Conditions, or any other
              agreement between you and Mito.Money;
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>4.10.2</b> open more than one account, without our prior
              written permission;
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>4.10.3</b> provide false, inaccurate, or misleading
              information;
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>4.10.4</b> allow anyone else access to your registration
              details, and will keep those details secure;
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>4.10.5</b> refuse to provide confirmation of any information
              you provide to us, including proof of identity, or refuse to
              co-operate in any investigation;
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>4.10.6</b> use an anonymising proxy (a tool that attempts to
              make activity untraceable); or
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>4.10.7</b> copy or monitor our website using any robot, spider,
              or other automatic device or manual process, without our prior
              written permission.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>4.11</b> Mito.Money may, as necessary in providing the Service,
              store all information required of a Recipient to prove his or her
              identity or associated with their specific Transaction. Such
              proofs may include a suitable form of valid, unexpired
              identification from a list of acceptable papers provided by the
              Service Provider, and/or a Transaction tracking number, a personal
              identification number (PIN), a "password", a "secret word", or
              other similar identifiers.
            </Heading>
          </Box>

          {/* 5 */}
          <Box mt="50px">
            <Heading
              as="p"
              size="xxl_normal"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              id="terms-cancellation-refunds"
            >
              Cancellation & Refunds
            </Heading>

            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>5.1</b> If you have any problems using the Service you should
              contact us through the channels listed at the end of this Terms
              and Conditions.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>5.2</b> You have the statutory right to cancel your agreement
              with us after you have submitted a transaction?. This right of
              cancellation continues until fourteen days after you have
              submitted the transaction, or until we have completed the contract
              by paying the Pay-out Amount to the Recipient, whichever is the
              earlier. If you exercise your right to cancel under this clause,
              we may make a cancellation charge.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>5.3</b> If you wish to exercise your right to cancel under this
              clause, you must submit a written request to one of the contact
              points listed at the bottom of this Terms and Conditions, giving
              the Sender's full name, address, and phone number, together with
              the Transaction tracking/reference number, Transaction Amount, and
              the reason for your refund request.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>5.4</b> Any refunds will be credited back to the same Payment
              Instrument used to fund the Transaction and in the same currency.
              No adjustment will be made for any currency fluctuations, which
              may have occurred in the meanwhile.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>5.5</b> Refunds once approved are initiated on the same
              business day but could take 2-5 working days to reflect in your
              account.
            </Heading>
          </Box>
          {/* 6 */}
          <Box mt="50px">
            <Heading
              as="p"
              size="xxl_normal"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              id="terms-collection-of-information"
            >
              Collection of Information
            </Heading>

            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>6.1</b> Customer Identification Program. UK law requires all
              financial institutions to assist in the fight against money
              laundering activities and the funding of terrorism by obtaining,
              verifying, and recording identifying information about all
              customers. We may therefore require you to supply us with personal
              identifying information and we may also legally consult other
              sources to obtain information about you.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>6.2</b> Verification and Checks. We will verify your
              residential address and personal details in order to confirm your
              identity. We may also pass your personal information to a credit
              reference agency, which may keep a record of that information. You
              can be rest assured that this is done only to confirm your
              identity, that a credit check is not performed and that your
              credit rating will be unaffected. All information provided by you
              will be treated securely and strictly in accordance with the Data
              Protection Act 1998.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>6.3</b> By accepting these Terms and Conditions you authorise
              us to make any inquiries we consider necessary to validate the
              information that you provide to us. We may do this directly, for
              example by asking you for additional information, requiring you to
              take steps to confirm ownership of your Payment Instruments or
              email address; or by verifying your information against third
              party databases; or through other sources.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>6.4</b> Data Privacy Policy. You consent to our processing your
              personal information for the purposes of providing the Service,
              including for verification purposes as set out in this clause. You
              also consent to the use of such data for communicating with you,
              and for statutory, accounting and archival purposes. You
              acknowledge that you have read and consented to Mito.Money's Data
              Privacy Policy. The Data Privacy Policy can be found by clicking
              here: Data Privacy Policy.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>6.5</b> Government Disclosures. We may be required by law to
              provide information about you and your Transactions to government
              or other competent authorities as described in our Data Privacy
              Policy. You acknowledge and consent to our doing this.
            </Heading>
          </Box>
          {/* 7 */}
          <Box mt="50px">
            <Heading
              as="p"
              size="xxl_normal"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              id="terms-intellectual-property-rights"
            >
              Intellectual Property Rights
            </Heading>

            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>7.1</b> The Mito.Money website and the Mito.Money Service, the
              content, and all intellectual property relating to them and
              contained in them (including but not limited to copyrights,
              patents, database rights, trademarks and service marks) are owned
              by us, our affiliates, or third parties. All right, title and
              interest in and to the Mito.Money Online Site and the Mito.Money Online
              Service shall remain our property and/or the property of such
              other third parties.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>7.2</b> The Mito.Money website and the Mito.Money Service may be used
              only for the purposes permitted by these Terms and Conditions or
              described on this website. You are authorized solely to view and
              to retain a copy of the pages of the Mito.Money website for your own
              personal use. You may not duplicate, publish, modify, create
              derivative works from, participate in the transfer or sale of,
              post on the internet, or in any way distribute or exploit the
              Mito.Money website, the Mito.Money Service or any portion thereof for
              any public or commercial use without our express written
              permission. You may not: (a) use any robot, spider, scraper or
              other automated device to access the Mito.Money website or the
              Mito.Money Service; and/or (b) remove or alter any copyright,
              trademark or other proprietary notice or legend displayed on the
              Mito.Money website (or printed pages of the website). The name
              Mito.Money and other names and indicia of ownership of Mito.Money's
              products and/or services referred to on the Mito.Money website are
              our exclusive marks or the exclusive marks of other third parties.
              Other product, service and company names appearing on the website
              may be trademarks of their respective owners.
            </Heading>
          </Box>
          {/* 8 */}
          <Box mt="50px">
            <Heading
              as="p"
              size="xxl_normal"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              id="terms-warranties-liability"
            >
              Warranties & Liability
            </Heading>

            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>8.1</b> We will refund to you any benefit, which we receive as
              a result of any breach of our agreement with you (this means that,
              for example, where a money transfer has failed in such
              circumstances we will refund to you the Transaction Amount and the
              Service Fee).
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>8.2</b> If a money transfer is delayed or fails, you may have a
              right to receive a refund under laws relating to the provision of
              international money transfer services. We will provide you with
              the details of your rights to a refund if you contact us using the
              contact details at the end of this agreement.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>8.3</b> Any claim for refund made by you and/or a Recipient
              (who is not registered with us) must be supported by any available
              relevant documentation.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>8.4</b> If any loss which you or a Recipient (who is not
              registered with us) suffers is not covered by a right to payment
              under the laws referred to in clause 8.2, we will only accept
              liability for that loss up to a limit which is the greater of: (a)
              the amount of any service charge; and (b) €500, unless otherwise
              agreed by us in writing. Our cap on our liability only limits a
              claim for loss arising out of any single transaction or related
              transactions, or (if a loss does not arise out of a transaction or
              transactions) any single act, omission or event or related acts,
              omissions or events. This means that if, for example, you suffer
              loss by reason of our failure to perform our agreement with you
              under two unrelated transactions, you might be able to claim up to
              €1000.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>8.5</b> We do not, in any event, accept responsibility for:
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>8.5.1</b> any failure to perform your instructions as a result
              of circumstances which could reasonably be considered to be
              outside our control;
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>8.5.2</b> malfunctions in communications facilities which
              cannot reasonably be considered to be under our control and that
              may affect the accuracy or timeliness of messages you send to us;
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>8.5.3</b> any losses or delays in transmission of messages
              arising out of the use of any internet service provider or caused
              by any browser or other software which is not under our control;
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>8.5.4</b> errors on the website or with the Service caused by
              incomplete or incorrect information provided to us by you or a
              third party.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>8.6</b> Nothing in this clause 8 shall (a) exclude or limit
              liability on our part for death or personal injury resulting from
              our negligence; or (b) exclude liability for our fraud.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>8.7</b> Where you are sending a money transfer to a Recipient
              who is not registered with us, you agree to accept the provisions
              of this clause 11 not only for yourself, but also on behalf of the
              Recipient.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>8.8</b> Your relationship is with Mito.Money only. You agree that
              no affiliate or agent of Mito.Money owes you any duty of care when
              performing a task which would otherwise have to be performed by
              Mito.Money under its agreement with you.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>8.9</b> You agree to indemnify and hold harmless Mito.Money, our
              subsidiaries, affiliates, officers, directors, employees, agents,
              independent contractors, advertisers, partners, and co-branders
              from all loss, damage, claims, actions or demands, including
              reasonable legal fees, arising out of your use or misuse of this
              website or Service, all activities that occur under your password
              or account email login, your violation of this Terms and
              Conditions or any other violation of the rights of another person
              or party.
            </Heading>
          </Box>
          {/* 9 */}
          <Box mt="50px">
            <Heading
              as="p"
              size="xxl_normal"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              id="terms-electronic-communications"
            >
              Electronic Communications
            </Heading>

            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>9.1</b> You acknowledge that this Terms and Conditions shall be
              entered into electronically, and that the following categories of
              information ("Communications") may be provided by electronic
              means:
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>9.1.1</b> This Terms and Conditions and any amendments,
              modifications or supplements to it.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>9.1.2</b> Your records of transactions through the Service.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>9.1.3</b> Any initial, periodic or other disclosures or notices
              provided in connection with the Service, including without
              limitation those required by law.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>9.1.4</b> Any customer service communications, including
              without limitation communications with respect to claims of error
              or unauthorised use of the Service.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>9.1.5</b> Any other communication related to the Service or
              Mito.Money.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>9.2</b> The Service does not allow for Communications to be
              provided in paper format or through other non-electronic means.
              You may withdraw your consent to receive Communications
              electronically, but if you do, your use of the Service shall be
              terminated. In order to withdraw your consent, you must contact us
              using our contact information at the end of this Terms and
              Conditions.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>9.3</b> In order to access and retain Communications, you must
              have or have access to the following:
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>9.3.1</b> An Internet browser that supports 128-bit encryption,
              such as Internet Explorer version 4.0 or above;
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>9.3.2</b> An email account and email software capable of
              interfacing with Mito.Money's e-mail servers;
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>9.3.3</b> A personal computer, operating system and
              telecommunications connections to the Internet capable of
              supporting the foregoing;
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>9.3.4</b> Sufficient electronic storage capacity on your
              computer's hard drive or other data storage unit; and
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>9.3.5</b> A printer that is capable of printing from your
              browser and e-mail software. In addition, you must promptly update
              us with any change in your email address by updating your profile
              at www.mito.money.
            </Heading>
          </Box>
          {/* 10 */}
          <Box mt="50px">
            <Heading
              as="p"
              size="xxl_normal"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              id="terms-termination"
            >
              Termination
            </Heading>

            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>10.1</b> Either party may terminate these Terms and Conditions
              on one day's written notice.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>10.2</b> We may terminate this Terms and Conditions with
              immediate effect if you:
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>10.2.1</b> become, or are likely to become, insolvent or are
              declared bankrupt;
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>10.2.2</b> are in breach of any provision of this Terms and
              Conditions;
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>10.2.3</b> your use of the Service or the website is disruptive
              to our other customers, or you do anything which in our opinion is
              likely to bring us into disrepute;
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>10.2.4</b> breaches or attempts to breach the security of the
              website (including but not limited to: modifying or attempting to
              modify any information; unauthorised log-ins, unauthorised data
              access or deletion; interfering with the service, system, host or
              network; reverse engineering of any kind; spamming; hacking;
              falsifying data; introducing viruses, Trojan horses, worms or
              other destructive or damaging programs or engines; or testing
              security in any way);
            </Heading>
          </Box>
          {/* 11 */}
          <Box mt="50px">
            <Heading
              as="p"
              size="xxl_normal"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              id="terms-complaints"
            >
              Complaints
            </Heading>

            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>11.1</b> If you wish to make a complaint about any aspect of
              the Mito.Money service, please send your complaint in writing to the
              address shown on the Contact Us page or use our contact form on
              this website.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>11.2</b> We will acknowledge receipt of your complaint within 5
              business days. We will investigate your complaint and come back to
              you with the results of our investigation no later than 10
              business days of receipt of your complaint. If you are not
              satisfied with the manner in which we have dealt with your
              complaint, or the outcome, then you may refer the matter to the
              Financial Ombudsman Service, South Quay Plaza, 183 Marsh Wall,
              London E14 9SR, Tel No 08000234567,
              Email:complaint.info@financial-ombudsman.org.uk
            </Heading>
          </Box>
          {/* 12 */}
          <Box mt="50px">
            <Heading
              as="p"
              size="xxl_normal"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              id="terms-general"
            >
              General
            </Heading>

            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>12.1</b> Governing law: this Agreement will be governed by
              English law and the parties submit to the exclusive jurisdiction
              of the English Courts.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>12.2</b> No Waiver: The failure of Mito.Money to exercise or
              enforce any right or provision of the Terms and Conditions shall
              not constitute a waiver of such right or provision.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>12.3</b> Modification: We may modify this Terms and Conditions
              from time to time without notice to you, except as may be required
              by law. You can review the most current version of the Terms and
              Conditions at any time by reviewing this website. You may
              terminate your use of the Service if you do not agree with any
              modification or amendment. If you use the Service after the
              effective date of an amendment or modification, you shall be
              deemed to have accepted that amendment or modification. You agree
              that you shall not modify these Terms and Conditions and
              acknowledge that any attempts by you to modify these Terms and
              Conditions shall be void.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>12.4</b> Entire Agreement: This agreement constitutes the
              entire agreement between the parties and supersedes all prior
              understandings or agreements relating to the subject matter of
              this agreement.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>12.5</b> Severability: If any provision of the Terms and
              Conditions is found by an arbitrator or court of competent
              jurisdiction to be invalid, the parties nevertheless agree that
              the arbitrator or court should endeavour to give appropriately
              valid effect to the intention of the Terms and Conditions as
              reflected in the provision, and the other provisions of the Terms
              and Conditions shall remain in full force and effect.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              <b>12.6</b> Any external links to third-party websites on the
              website are provided as a convenience to you. We do not control
              these sites in any way and we are not responsible for the
              accuracy, completeness, legality or any other aspect of these
              other sites including any content provided on them. You access
              such websites at your own risk.
            </Heading>
          </Box>
          {/* 13 */}
          <Box mt="50px">
            <Heading
              as="p"
              size="xxl_normal"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              id="terms-security"
            >
              Security
            </Heading>

            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              We take security very seriously at Mito.Money, and we work hard,
              using state-of-the-art security measures, to make sure that your
              information remains secure. The Mito.Money Service is a safe and
              convenient way to send money to friends and family and to other
              people that you trust. However, we do advise you to consider very
              carefully before sending money to anyone that you do not know
              well.
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              If you are aware of anyone or any entity that is using the Service
              inappropriately, please email us using our contact form.
              Similarly, if you receive any emails, purporting to be from
              Mito.Money, which you suspect may be "phishing" (fake) emails, please
              forward them to us using our contact form.
            </Heading>
          </Box>
          {/* 14 */}
          <Box mt="50px">
            <Heading
              as="p"
              size="xxl_normal"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              id="terms-contact-information"
            >
              Contact Information
            </Heading>

            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              Questions, notices, and requests for refunds or further
              information should be sent to Mito.Money, as follows:
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              By Email:
              <Link color="blue.400" ml="2" href={`mailto:${tenancyConfigData?.contactEmailAddress}`}>
                {tenancyConfigData?.contactEmailAddress}
              </Link>
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              By telephone/WhatsApp:
              <Link color="blue.400" ml="2" href="#">
                {tenancyConfigData?.mobile}
              </Link>
            </Heading>
            <Heading
              as="p"
              size="lg_light"
              color="rgb(33, 33, 33)"
              textTransform="initial"
              lineHeight="28px"
              pt="20px"
            >
              By post: Mito.Money C/O Funtech Global Communications Ltd, Devonshire
              House, Manor way, Borehamwood, Hertfordshire, WD6 1QQ.
            </Heading>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};
export default TermsConent;
